import { Card, CardBody, Center, Image, useBreakpointValue, VStack } from '@chakra-ui/react';

import { Design } from '@/lib/types';

import { ReactNode, useEffect, useState } from 'react';
import { getDesignPreviewImage } from '@/lib';

import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../ui/LoadingSpinner';
import AddToCartModal from '../cart/AddToCartModal';

const BORDER_RADIUS = 9;

const THUMBNAIL_MOBILE_SIZE = 162;
const THUMBNAIL_DESKTOP_SIZE = 183;

type RemixCardProps = {
  design: Design;
  likingForDesign?: string;
  hasLiked?: boolean;
  onLike?: () => void;
  onAddToCart?: () => void;
  height?: { base: number; lg: number };
  children: ReactNode;
  childrenOnHover?: ReactNode;
};

const DesignCardContainer = ({ design, height, children, childrenOnHover }: RemixCardProps) => {
  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(true);
  const [designPreviewImage, setDesignPreviewImage] = useState<string>(null);

  const [isAddToCartModalVisible, setAddToCartModalVisible] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const history = useHistory();

  const isMobile = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    getDesignPreviewImage(design)
      .then(({ previewImage }) => {
        setDesignPreviewImage(previewImage);

        setIsLoadingPreviewImage(false);
      })
      .catch((e) => console.log('E', e));
  }, []);

  const { id } = design;

  const handleGoToEditor = () => {
    history.push(`/designs/${design.id}`);
  };

  return (
    <>
      <Card
        bg="transparent"
        borderRadius={BORDER_RADIUS}
        border="1px solid"
        borderColor={{
          base: 'borderSecondary',
          lg: isHovered ? 'brand.600' : 'borderSecondary',
        }}
        boxShadow="none"
        h={height || 'auto'}
        w={{ base: '100%', '2sm': 183 }}
        key={id}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        overflow="hidden"
        position="relative"
      >
        <CardBody p={0} h="100%" w="100%">
          <Center
            bg={isHovered ? 'rgba(6, 74, 196, 0.1)' : 'secondaryLightGray'}
            cursor="pointer"
            h={{ base: `${THUMBNAIL_MOBILE_SIZE}px`, lg: `${THUMBNAIL_DESKTOP_SIZE}px` }}
            w={{ base: '100%', lg: '100%' }}
            onClick={() => handleGoToEditor()}
            overflow="hidden"
            position="relative"
          >
            {isLoadingPreviewImage || !designPreviewImage ? null : (
              <Image h="100%" w="100%" src={designPreviewImage} />
            )}
            {isLoadingPreviewImage ? (
              <Center
                position="absolute"
                w="100%"
                h="100%"
                bg={isLoadingPreviewImage ? 'transparent' : 'black'}
                opacity="0.5"
                top={0}
              >
                <LoadingSpinner />
              </Center>
            ) : null}
          </Center>
          <VStack align="flex-start" p={{ base: '4px 12px', md: '12px 16px' }} spacing="2px">
            {isMobile || !isHovered || !childrenOnHover ? children : null}
            {isMobile || isHovered ? childrenOnHover : null}
          </VStack>
        </CardBody>
      </Card>
      {isAddToCartModalVisible ? (
        <AddToCartModal
          designId={design.id}
          goBackText="Go Back"
          onClose={() => setAddToCartModalVisible(false)}
        />
      ) : null}
    </>
  );
};

export default DesignCardContainer;
