import { BrandType, User } from '@/components/types';
import { Flex, useBreakpointValue, VStack } from '@chakra-ui/react';

import ProductList from './components/ProductList';
import FeaturedBrands from './components/FeaturedBrands';
import { useParams } from 'react-router-dom';
import BrandHeader from './components/BrandHeader';
import { isValidUUID } from '@/utils/validation';
import { useBrandById, useBrandBySlug, useBrands } from '@/api/brands';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { partition } from 'lodash';

interface ProductsListProps {
  me?: User;
}

const ProductsPage = ({ me }: ProductsListProps) => {
  const { idOrSlug } = useParams<{ idOrSlug: string }>();
  const isUUID = isValidUUID(idOrSlug || '');
  const isBrandPage = !!idOrSlug;

  const { brands, isLoading: isLoadingBrands } = useBrands();

  const isMobile = useBreakpointValue({ base: true, md: false });

  // Call hooks unconditionally, but only one will be enabled
  const { brand: brandById, isLoading: isLoadingById } = useBrandById(isUUID ? idOrSlug || '' : '');
  const { brand: brandBySlug, isLoading: isLoadingBySlug } = useBrandBySlug(
    !isUUID ? idOrSlug || '' : ''
  );

  // Use the appropriate data based on the identifier type
  const brand = isUUID ? brandById : brandBySlug;
  const isLoading = isUUID ? isLoadingById : isLoadingBySlug;

  // Show loading state while fetching brand data
  if (isBrandPage && isLoading) {
    return (
      <Flex h="100%" justify="center" align="center">
        <LoadingSpinner />
      </Flex>
    );
  }

  const [apparelBrands, artworkBrands] = partition(
    brands,
    ({ type }) => type === BrandType.APPAREL
  );

  return (
    <Flex h="100%" bg="#FFFFFF" direction="column" overflow="auto">
      {isBrandPage ? (
        brand ? (
          <BrandHeader brand={brand} />
        ) : null
      ) : isLoadingBrands ? (
        <LoadingSpinner />
      ) : (
        <VStack
          padding={{ base: '16px 0px 8px 16px', md: '24px 32px 8px 32px' }}
          align="flex-start"
          gap={{ base: '24px', md: '44px' }}
        >
          <FeaturedBrands
            brands={artworkBrands}
            title={`${isMobile ? '' : 'Featured '}Artwork Community Brands`}
          />
          <FeaturedBrands
            brands={apparelBrands}
            title={`${isMobile ? '' : 'Popular '}Apparel & Accessories Brands`}
          />
        </VStack>
      )}

      <ProductList user={me} brandId={brand?.id} />
    </Flex>
  );
};

export default ProductsPage;
