import { Box, HStack, Text } from '@chakra-ui/react';

import { ReactNode, useState } from 'react';
import IconArrow from './IconArrow';

interface FilterContainerProps {
  label: string;
  children: ReactNode;
  isCollapsible?: boolean;
}

const FilterContainer = ({ label, children, isCollapsible }: FilterContainerProps) => {
  const [isExpanded, setExpanded] = useState(isCollapsible ? false : true);

  return (
    <Box w="100%">
      <HStack
        mb="10px"
        onClick={isCollapsible ? () => setExpanded(!isExpanded) : null}
        spacing="3px"
      >
        <Text color="secondaryDarkGray.600" textStyle="bodySmall" cursor="pointer">
          {label}
        </Text>
        {isCollapsible ? <IconArrow transform={isExpanded ? 'none' : 'rotate(180deg)'} /> : null}
      </HStack>

      {isExpanded ? children : null}
    </Box>
  );
};

export default FilterContainer;
