import { Icon } from '@chakra-ui/react';

const IconDescendant = (props) => (
  <Icon
    width="20px"
    height="23px"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 1C1.5 0.723858 1.27614 0.5 1 0.5C0.723858 0.5 0.5 0.723858 0.5 1H1.5ZM19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L16.1716 15.4645C15.9763 15.2692 15.6597 15.2692 15.4645 15.4645C15.2692 15.6597 15.2692 15.9763 15.4645 16.1716L18.2929 19L15.4645 21.8284C15.2692 22.0237 15.2692 22.3403 15.4645 22.5355C15.6597 22.7308 15.9763 22.7308 16.1716 22.5355L19.3536 19.3536ZM0.5 1V17H1.5V1H0.5ZM3 19.5H19V18.5H3V19.5ZM0.5 17C0.5 18.3807 1.61929 19.5 3 19.5V18.5C2.17157 18.5 1.5 17.8284 1.5 17H0.5Z"
      fill="#A0AEC0"
    />
  </Icon>
);

export default IconDescendant;
