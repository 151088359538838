import { useEffect, useState } from 'react';

import { Template } from '@/lib/types';
import ImagePreviewGallery from '@/components/images/ImagePreviewGallery';
import { findIndex } from 'lodash';

interface TemplatePreviewGalleryProps {
  template: Template;
  onZoom?: () => void;
  onSelectedIndex?: (index: number) => void;
}

const TemplatePreviewGallery = ({
  template,
  onZoom,
  onSelectedIndex,
}: TemplatePreviewGalleryProps) => {
  const [selectedImageId, setSelectedImageId] = useState<string>(null);

  useEffect(() => {
    if (!template?.colors && !template.sides) {
      return;
    }

    setSelectedImageId(
      template.sides[0].placeholderImageUrl ? template.sides[0].id : template.colors[0].images[0].id
    );
  }, [template.id, template.colors, template.sides]);

  const { colors, mockupImages, sides } = template;

  const imagesForSides = sides.map(({ id, placeholderImageUrl }) => {
    if (placeholderImageUrl) {
      return { id, url: placeholderImageUrl };
    }

    const image = colors[0].images.find(({ templateSideId }) => templateSideId === id);

    return image && { url: image.url, id: image.id };
  });

  const images = [...imagesForSides, ...(mockupImages || [])];

  return (
    <ImagePreviewGallery
      images={images}
      onSelectedImageId={(id) => {
        setSelectedImageId(id);

        if (onSelectedIndex) {
          onSelectedIndex(findIndex(images, (image) => image.id === id));
        }
      }}
      selectedImageId={selectedImageId}
      onZoom={onZoom}
    />
  );
};

export default TemplatePreviewGallery;
