import { fabric } from 'fabric-spacerunners';
import { renderPath } from './utils';

const starSVGPath =
  'M13.3656 6.55373L10.3553 1.55176L7.34509 6.55373L1.65771 7.87094L5.48466 12.2795L4.9799 18.0956L10.3553 15.8183L15.7307 18.0956L15.226 12.2795L19.0529 7.87094L13.3656 6.55373Z';

const BASE_WIDTH = 21;

export const Star = fabric.util.createClass(fabric.Object, {
  type: 'star',

  initialize(options = {}) {
    this.callSuper('initialize', options);
    this._createPathData();
  },

  _createPathData() {
    this.path = fabric.util.makePathSimpler(fabric.util.parsePath(starSVGPath));

    return this;
  },

  _render(ctx) {
    renderPath(this.path, this.width, this.height, BASE_WIDTH, ctx);

    this._renderPaintInOrder(ctx);
  },
});

fabric.Star = Star;

fabric.Star.fromObject = function (object, callback) {
  return fabric.Object._fromObject('Star', object, callback);
};
