import { getIpHistory } from '@/api/designs';
import ModalContainer from '@/components/modals/ModalContainer';
import { Design } from '@/lib';
import { Box, HStack, Show, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import IpHistoryCard from './IpHistoryCard';
import IconDescendant from './icons/IconDescendant';

const ICON_DESCENDANT_WIDTH = 18;
const LEFT_DESCENDANT_PADDING = 22;
const ICON_DESCENDANT_RIGHT_SPACING = 16;

interface IpHistoryModalProps {
  design: Design;
  onClose: () => void;
}

const IpHistoryModal = ({ design, onClose }: IpHistoryModalProps) => {
  const [ancestors, setAncestors] = useState<Design[]>([]);
  const [remixes, setRemixes] = useState<Design[]>([]);

  useEffect(() => {
    const loadIpHistory = async () => {
      const ipHistory = await getIpHistory(design);

      const { ancestors, remixes } = ipHistory;

      setAncestors(ancestors);
      setRemixes(remixes);
    };

    loadIpHistory();
  }, []);

  const allDesigns = [...[...ancestors].reverse(), design, ...remixes];

  const firstRemixIndex = ancestors.length + 1;

  return (
    <ModalContainer
      title="Artwork History"
      onClose={onClose}
      overflow={{ base: 'auto', md: 'visible' }}
      width={{ base: 'calc(100% - 20px)', md: '1024px' }}
      maxW="none"
    >
      <VStack mt="14px" pb="30px" spacing="12px">
        {allDesigns.map((item, index) => {
          const isRemix = index >= firstRemixIndex;

          const isLastRemix = index === allDesigns.length - 1;

          return (
            <Box
              pl={{
                base: 0,
                md: `${
                  Math.min(index, firstRemixIndex) * LEFT_DESCENDANT_PADDING +
                  (Math.min(index, firstRemixIndex) - 1) *
                    (ICON_DESCENDANT_WIDTH + ICON_DESCENDANT_RIGHT_SPACING)
                }px`,
              }}
              w="100%"
            >
              <HStack position="relative" spacing={`${ICON_DESCENDANT_RIGHT_SPACING}px`} w="100%">
                {isRemix ? (
                  <Show above="md">
                    <Box
                      bg="#A0AEC0"
                      width={isRemix ? '1.5px' : 0}
                      height={isLastRemix ? '50%' : 'calc(100% + 12px)'}
                      position="absolute"
                      top={0}
                    />
                  </Show>
                ) : null}
                {index !== 0 ? (
                  <Show above="md">
                    <IconDescendant position="relative" bottom="6px" />
                  </Show>
                ) : null}
                <IpHistoryCard
                  design={item}
                  key={item.id}
                  isCurrent={design.id === item.id}
                  isOriginal={index === 0}
                ></IpHistoryCard>
              </HStack>
            </Box>
          );
        })}
      </VStack>
    </ModalContainer>
  );
};

export default IpHistoryModal;
