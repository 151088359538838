import { LicenseTerm } from '@/components/types/story-protocol';

export enum LicenseType {
  OPEN_USE = 'openUse',
  NON_COMMERCIAL_REMIX = 'nonCommercialRemix',
  COMMERCIAL_USE = 'commercialUse',
  COMMERCIAL_REMIX = 'commercialRemix',
}

export const LICENSE_PROPERTIES = {
  openUse: {
    title: 'Open Use',
    description:
      'Open Domain is a public dedication tool, which enables creators to give up their copyright and put their works into the worldwide public domain. Reusers can distribute, remix, adapt, and build upon the asset in any medium or format, with no conditions.',
    caption: 'Least Restrictive',
    primaryColor: '#064AC4',
    secondaryColor: '#064AC40F',
  },
  nonCommercialRemix: {
    title: 'Non-Commercial Remix',
    description:
      'This license enables reusers to copy and distribute the material in any medium or format in unadapted form only, for noncommercial purposes only, and only so long as attribution is given to the creator.',
    caption: 'Get credit for your work!',
    primaryColor: '#6040B0',
    secondaryColor: '#EDE6FF',
  },
  commercialUse: {
    title: 'Commercial Use',
    description:
      'This license enables reusers to copy and distribute the material in any medium or format in unadapted form only, for commercial purposes, and only so long as attribution is given to the creator.',
    caption: 'Get Paid For YOUR Work!',
    primaryColor: '#1C8D02',
    secondaryColor: '#EBFFE7',
  },
  commercialRemix: {
    title: 'Commercial Remix',
    description:
      'This license enables reusers to remix, adaptations, copy and distribute the material in any medium or format , for commercial purposes, so long as attribution is given to the creator.',
    caption: 'Get Paid & GET Credit',
    primaryColor: '#1C8D02',
    secondaryColor: '#FFEAF5',
  },
};

export const getLicenseType = (licenseTerm: LicenseTerm): LicenseType => {
  const { commercialUse, commercialAttribution, derivativesAllowed, derivativesAttribution } =
    licenseTerm.terms;

  if (!commercialAttribution && derivativesAllowed && !derivativesAttribution) {
    return LicenseType.OPEN_USE;
  }

  if (!commercialUse && derivativesAllowed) {
    return LicenseType.NON_COMMERCIAL_REMIX;
  }

  if (commercialUse && !derivativesAllowed) {
    return LicenseType.COMMERCIAL_USE;
  }

  return LicenseType.COMMERCIAL_REMIX;
};
