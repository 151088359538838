import { Icon } from '@chakra-ui/react';

const IconLeft = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2274 17.345H6.86741V8.04503H1.71948V2.65503H12.2274V4.15503H3.21949V6.54503H8.36741V15.845H12.2274V17.345Z"
      fill="black"
    />
    <path
      d="M12.2224 7.01514C10.5624 7.01514 9.22241 5.66514 9.22241 4.01514V3.01514H15.2324V4.01514C15.2324 5.67514 13.8824 7.01514 12.2324 7.01514H12.2224ZM10.2224 4.01514C10.2224 5.11514 11.1224 6.01514 12.2224 6.01514C13.3224 6.01514 14.2224 5.11514 14.2224 4.01514H10.2224Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1057 2.65479H11.5381V4.15479H17.6057V15.8589H12.0251V17.3589H19.1057V2.65479Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4469 16.2954V8.02808H14.4469V16.2954H15.4469Z"
      fill="black"
    />
  </Icon>
);

export default IconLeft;
