import {
  VStack,
  Center,
  Box,
  Image,
  useBreakpointValue,
  TabList,
  Tab,
  Tabs,
  Text,
  HStack,
  Button,
} from '@chakra-ui/react';
import { sortBy } from 'lodash';
import LoadingSpinner from '../ui/LoadingSpinner';
import ModalContainer from '../modals/ModalContainer';
import { useGraphicCategories } from '../../api/graphics';
import { useState } from 'react';
import { Graphic, GraphicCategory, GraphicCollection } from '../../lib';
import { H4 } from '../typography/Headings';
import { IAbloImage } from '@space-runners/ablo-ts-sdk';
import IconToggle from '../sidebar/IconToggle';

interface AddAddGraphicsModalProps {
  onClose: () => void;
  onGraphicSelected: (graphicId: string) => void;
  onImageSelected: (image: IAbloImage, graphicId?: string) => void;
  onSvgSelected: (svgAsString: string, isColorizable: boolean, graphicId?: string) => void;
}

export default function AddGraphicsModal({
  onClose,
  onImageSelected,
  onSvgSelected,
  onGraphicSelected,
}: AddAddGraphicsModalProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const ICON_SIZE = isMobile ? '40px' : '60px';
  const GRAPHIC_CONTAINER_SIZE = isMobile ? '87px' : '122px';
  const GRAPHIC_SIZE = isMobile ? '76px' : '90px';

  const [category, setCategory] = useState<GraphicCategory>(null);
  const [collection, setCollection] = useState<GraphicCollection>(null);

  const { graphicCategories, isLoading } = useGraphicCategories();
  const categories = sortBy(graphicCategories, 'order');

  const handleSelectCategory = async (index) => {
    const category = categories[index];
    setCategory(category);
    setCollection(null);
  };

  const handleSelectCollection = async (collection) => {
    setCollection(collection);
  };

  const handleSelectGraphic = async (graphic: Graphic) => {
    const urlNonCached = `${graphic.url}?t=${Date.now()}`;
    onGraphicSelected(graphic.id);
    if (graphic.url.endsWith('.svg')) {
      fetch(urlNonCached)
        .then((response) => response.text())
        .then((iconAsSvgString) => {
          if (iconAsSvgString) {
            onSvgSelected(iconAsSvgString, graphic.isColorizable, graphic.id);
          }
          onClose();
        });
    } else {
      onImageSelected(
        {
          id: null,
          url: urlNonCached,
        },
        graphic.id
      );
      onClose();
    }
  };

  if (categories?.length && !category) {
    handleSelectCategory(0);
  }

  return (
    <ModalContainer title="Add graphic" onClose={onClose} maxW={{ base: '100%', md: '720px' }}>
      <Box>
        {isLoading ? (
          <Center h="300px">
            <LoadingSpinner />
          </Center>
        ) : (
          <VStack w="100%" mt="24px" gap="24px" align="start">
            <Tabs justifyItems="start" onChange={handleSelectCategory}>
              <TabList justifyItems="start">
                {categories?.map((category) => (
                  <Tab key={category.id} border="none">
                    <Image w="21px" h="20px" src={category.iconUrl} marginEnd="4px" />
                    {category.name}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
            {!collection && category?.collections?.length && (
              <HStack
                overflowX="auto"
                spacing={4}
                w="100%"
                display="grid"
                gridTemplateColumns="repeat(2, 1fr)"
                css={{
                  '&::-webkit-scrollbar': { display: 'none' },
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none',
                }}
              >
                {category.collections.map((collection) => (
                  <VStack
                    key={collection.id}
                    bgImage={`linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%), url(${collection.cardImageUrl})`}
                    bgSize="cover"
                    bgPosition="center"
                    h={{ base: '152px', md: '160px' }}
                    w={{ base: '175px', md: '322px' }}
                    align="left"
                    borderRadius={8}
                    py={{ base: '11px', md: '15px' }}
                    px={{ base: '11px', md: '17px' }}
                    cursor="pointer"
                    onClick={() => handleSelectCollection(collection)}
                  >
                    {collection.iconUrl && (
                      <Image
                        src={collection.iconUrl}
                        alt={collection.name}
                        w={ICON_SIZE}
                        h={ICON_SIZE}
                      />
                    )}
                    <VStack align="left" mt="auto" spacing={{ base: '0px', md: '4px' }}>
                      <H4 color="white" w="165px">
                        {collection.name}
                      </H4>
                      {category.name === 'NFTs' && (
                        <Text color="white">NFTs: {collection.graphics?.length}</Text>
                      )}
                    </VStack>
                  </VStack>
                ))}
              </HStack>
            )}
            {collection?.graphics?.length && (
              <VStack gap="24px" w="100%" align="start">
                <HStack gap={0}>
                  <Button bg="transparent" onClick={() => setCollection(null)} padding={0}>
                    <IconToggle isRotated={false} w="24px" h="24px" />
                  </Button>
                  <H4>{collection.name}</H4>
                </HStack>
                <HStack
                  overflowX="auto"
                  gap={isMobile ? '4px' : '8px'}
                  w="100%"
                  display="grid"
                  gridTemplateColumns={`repeat(${isMobile ? '4' : '5'}, 1fr)`}
                  css={{
                    '&::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                  }}
                >
                  {collection.graphics.map((graphic) => (
                    <Box
                      key={graphic.id}
                      bgColor="#CDDAE899"
                      h={GRAPHIC_CONTAINER_SIZE}
                      w={GRAPHIC_CONTAINER_SIZE}
                      borderRadius={8}
                      cursor="pointer"
                      alignContent="center"
                      justifyItems="center"
                      onClick={() => handleSelectGraphic(graphic)}
                    >
                      <Image
                        src={graphic.url}
                        alt={graphic.name}
                        w={GRAPHIC_SIZE}
                        h={GRAPHIC_SIZE}
                        transition="transform 0.3s ease"
                        _hover={{
                          transform: 'scale(1.2)',
                        }}
                      />
                    </Box>
                  ))}
                </HStack>
              </VStack>
            )}
          </VStack>
        )}
      </Box>
    </ModalContainer>
  );
}
