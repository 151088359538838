import { useState } from 'react';

import { Modal, ModalOverlay, ModalContent, ModalBody, VStack } from '@chakra-ui/react';

import Button from '@/lib/components/Button';
import DesignNameInput from '@/views/demo/pages/submit-remix/components/DesignNameInput';

type Props = {
  onClose: () => void;
  onSave: (designName: string) => void;
  designName?: string;
  isRenaming: boolean;
  error?: string;
};

function RenameDesignModal({ isRenaming, onClose, onSave, designName, error }: Props) {
  const [name, setName] = useState(designName);

  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="10px" w="308px">
        <ModalBody padding="15px 12px">
          <VStack spacing="20px">
            <DesignNameInput error={error} value={name} onChange={(e) => setName(e.target.value)} />
            <Button
              isLoading={isRenaming}
              isDisabled={!name}
              onClick={() => onSave(name)}
              title="Save"
              w="100%"
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RenameDesignModal;
