import { Box, Button as ChakraButton, Flex, HStack, Text, useToast } from '@chakra-ui/react';

import { Cart, ShippingInfo } from '@/components/types';
import { format, parseISO } from 'date-fns';
import StatusBadge from './StatusBadge';
import Button from '@/components/button';

import { useEffect, useState } from 'react';
import IconToggle from './icons/IconToggle';
import { canCancelOrder, canTrackOrder } from '../utils';
import { deleteOrder, getShippingInfo } from '@/api/cart';
import { useHistory } from 'react-router-dom';
import { getFormattedAmount } from '../../utils/currency-formatter';
import OrderItemSummaryRow from './OrderItemSummaryRow';

interface OrderSummaryCardProps {
  order: Cart;
  onCanceledOrder: (id: string) => void;
}

const OrderSummaryCard = ({ order, onCanceledOrder }: OrderSummaryCardProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const [isCancelingOrder, setCancelingOrder] = useState(false);

  const [shippingInfo, setShippingInfo] = useState<ShippingInfo>(null);

  const toast = useToast();

  const { createdAt, estimatedDeliveryAt, id, items, orderStatus, totalPrice } = order;

  const history = useHistory();

  useEffect(() => {
    const loadShippingInfo = async () => {
      const shippingInfo = await getShippingInfo(order.id);

      setShippingInfo(shippingInfo);
    };

    loadShippingInfo();
  }, [order.id]);

  const handleCancelOrder = async () => {
    setCancelingOrder(true);

    try {
      await deleteOrder(id);

      toast({
        title: 'Order refunded',
      });

      onCanceledOrder(id);
    } catch {
      toast({
        title: 'Error cancelling order',
      });
    } finally {
      setCancelingOrder(false);
    }
  };

  return (
    <Box border="1px solid" borderColor="borderSecondary" borderRadius="12px" w="100%">
      <Flex
        bg="rgba(234, 234, 234, 0.56)"
        borderBottom={isOpen ? '1px solid' : 'none'}
        borderBottomColor="borderSecondary"
        borderTopLeftRadius="12px"
        borderTopRightRadius="12px"
        p="6px 20px"
        w="100%"
      >
        <Box w="187px">
          <Text as="b" mb="2px" textStyle="subtitle">
            #{order.shopifyOrderNumber}
          </Text>
          <Text color="secondaryDarkGray.600" textStyle="bodySuperSmall">
            Placed on {format(parseISO(createdAt), 'MMM dd, yyyy')}
          </Text>
        </Box>
        <Flex align="center" justify="space-between" ml="76px" w="237px">
          <Box>
            <Text color="secondaryDarkGray.600" mb="4px" textStyle="bodySuperSmall">
              Estimated delivery
            </Text>
            <Text textStyle="bodySmall">
              {estimatedDeliveryAt ? format(parseISO(estimatedDeliveryAt), 'MMM dd, yyyy') : 'N/A'}
            </Text>
          </Box>
          <StatusBadge status={orderStatus} />
        </Flex>
        <Box ml="77px" w="70px">
          <Text color="secondaryDarkGray.600" mb="4px" textStyle="bodySuperSmall">
            Order total:
          </Text>
          <Text as="b" textStyle="body">
            ${totalPrice}
          </Text>
        </Box>
        <HStack ml="95px" w="123px">
          <ChakraButton
            size="sm"
            mb="4px"
            onClick={() => history.push(`/orders/${id}`)}
            variant="link"
          >
            Order details
          </ChakraButton>
        </HStack>
        <HStack ml="49px" w="123px">
          {canCancelOrder(order.orderStatus.text) ? (
            <Button isLoading={isCancelingOrder} secondary small onClick={handleCancelOrder}>
              Cancel order
            </Button>
          ) : null}
          {canTrackOrder(order.orderStatus.text) ? (
            <Button small onClick={() => history.push(`/orders/${id}/tracking`)}>
              Track order
            </Button>
          ) : null}
        </HStack>
        <HStack flex={1} justify="flex-end" ml="62px">
          <Box as="button" onClick={() => setIsOpen(!isOpen)}>
            <IconToggle isOpen={isOpen} />
          </Box>
        </HStack>
      </Flex>
      {isOpen ? (
        <Box p="0 20px" position="relative" w="100%">
          {items.map((item, index) => (
            <OrderItemSummaryRow item={item} index={index} key={index} />
          ))}
          <Flex align="center" borderTop="1px solid" borderTopColor="borderSecondary" py="13px">
            <Box w="187px">
              <Text color="secondaryDarkGray.600" textStyle="bodySuperSmall">
                Shipping method:
              </Text>
            </Box>
            <Flex align="center" justify="space-between" ml="76px" w="237px">
              <Box>
                <Text textStyle="bodySuperSmall">{order.shippingMethod}</Text>
              </Box>
            </Flex>
            <Box ml="77px" w="70px">
              {shippingInfo?.costs?.total ? (
                <Text as="b" textStyle="body">
                  {getFormattedAmount(
                    shippingInfo?.costs?.currency,
                    parseInt(shippingInfo?.costs?.total, 10)
                  )}
                </Text>
              ) : null}
            </Box>
          </Flex>
        </Box>
      ) : null}
    </Box>
  );
};

export default OrderSummaryCard;
