import IconCircle from './icons/IconCircle';
import IconHeart from './icons/IconHeart';
import IconRectangle from './icons/IconRectangle';
import IconRoundedRectangle from './icons/IconRoundedRectangle';
import IconStar from './icons/IconStar';

export enum CropShape {
  RECTANGLE = 'rectangle',
  CIRCLE = 'circle',
  ROUNDED_RECTANGLE = 'rounded_rectangle',
  HEART = 'heart',
  STAR = 'star',
}

const SHAPES = [
  {
    value: CropShape.RECTANGLE,
    Component: IconRectangle,
  },
  {
    value: CropShape.CIRCLE,
    Component: IconCircle,
  },
  {
    value: CropShape.ROUNDED_RECTANGLE,
    Component: IconRoundedRectangle,
  },
  {
    value: CropShape.HEART,
    Component: IconHeart,
  },
  {
    value: CropShape.STAR,
    Component: IconStar,
  },
];

export default SHAPES;
