import {
  Card,
  CardBody,
  Center,
  Flex,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import CardMenu from './CardMenu';
import { useDeleteTemplate } from '@/api/templates';
import { useHistory } from 'react-router-dom';
import { Template, TemplateColorImage } from '@/components/types';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

type TemplateCardProps = {
  designCount: number;
  template: Template;
};

const TemplateCard = ({ designCount, template }: TemplateCardProps) => {
  const { id, colors, name, material, sides } = template;

  const history = useHistory();

  const { isDeleting, removeTemplate } = useDeleteTemplate();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleEdit = () => {
    history.push(`/template-library/edit/${id}`);
  };

  const handleDelete = (templateId: string) => removeTemplate(templateId);

  const { images = [] } = colors[0] || {};

  const imageUrl = isMobile
    ? template.collectionBannerImageMobile
    : template.collectionBannerImage ||
      (images as TemplateColorImage[]).find(({ templateSideId }) => templateSideId === sides[0]?.id)
        ?.url;

  return (
    <Card minH="356px" w="248px" bgColor="#FFFFFF" borderRadius={20} alignSelf="stretch">
      <CardBody p="36px 17px 22px 24px">
        <Center
          bg="#F7FAFC"
          borderRadius="18px"
          cursor="pointer"
          h="202px"
          onClick={handleEdit}
          overflow="hidden"
          w="100%"
        >
          {imageUrl ? <Image src={imageUrl} alt={imageUrl} h="100%" w="100%" /> : null}
          {isDeleting ? (
            <Center position="absolute" w="100%" h="100%" bg="black" opacity="0.5" top={0}>
              <LoadingSpinner />
            </Center>
          ) : null}
        </Center>
        <VStack align="left" m={0} bg={'white'} borderBottomRadius={10} pt="20px" spacing={0}>
          <Flex align="center" justify="space-between" position="relative" w="100%">
            <Text color="secondaryDarkGray.900" fontSize="15px" fontWeight={700}>
              {name}
            </Text>
            <CardMenu onDelete={() => handleDelete(id)} onEdit={handleEdit} />
          </Flex>
          <Text
            color="secondaryDarkGray.900"
            fontSize="15px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {material}
          </Text>
          <Text color="#6A6866" fontSize="11px"></Text>
          <Flex align="center" justify="space-between" mt="5px" w="100%">
            {designCount ? (
              <Text color="gray.200" fontSize="xs">
                {designCount} time{designCount === 1 ? '' : 's'}
              </Text>
            ) : null}
          </Flex>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default TemplateCard;
