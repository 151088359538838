import { Box, HStack, Image, Spinner, Text, VStack, useToast } from '@chakra-ui/react';

import { useState } from 'react';

import Cropper from 'react-easy-crop';

import Button from '../../../components/Button';
import ImageUpload from '../../../components/upload/ImageUpload';

import StyleSelector from '../components/style-selector';

import ImagesPreview from '../components/ImagesPreview';

import getCroppedImg, { DEFAULT_CROP, DEFAULT_ZOOM, IMAGE_WIDTH } from './cropImage';

import { IconDelete } from '../../../components/icons/IconDelete';

import { Favorite } from '@/lib';
import { DEFAULT_REFERENCE_IMAGE_STRENGTH } from '../../../../constants';
import { AbloSlider } from '../../components/AbloSlider';
import { CropTool } from '../../components/CropTool';
import CreditCostIcon from '../components/CreditCostIcon';
import { IAbloImage } from '@space-runners/ablo-ts-sdk/lib/interfaces/ablo-image.interface';
import { IStyle } from '@space-runners/ablo-ts-sdk/lib/services/style/style.interface';
import { IImageFileToImageRequest } from '@space-runners/ablo-ts-sdk/lib/services/photo-transformer/image-file-to-image-request.interface';
import NO_STYLE from '../NoStyle';
import { H5 } from '@/components/typography/Headings';
import { downsizeImageFileToBase64 } from '@/lib/utils/images';

const DEFAULT_PARAMS = {
  styleId: null,
  imageFile: null,
};

const SAMPLES = 2;

type ImageToImageGeneratorProps = {
  actionCost?: number;
  onGeneratedImageSelected: (image: IAbloImage) => void;
  styles: IStyle[];
  generateImageFromImage: (
    options: IImageFileToImageRequest,
    contentType: string,
    referenceContentType?: string
  ) => Promise<IAbloImage[]>;
  addFavorite: (favorite: Favorite) => void;
  favorites: Favorite[];
  removeFavorite: (id: string) => void;
};

export default function ImageToImageGenerator({
  actionCost,
  onGeneratedImageSelected,
  styles: imageStyles,
  generateImageFromImage,
  addFavorite,
  favorites,
  removeFavorite,
}: ImageToImageGeneratorProps) {
  const [waiting, setWaiting] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState(DEFAULT_CROP);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [options, setOptions] = useState<IImageFileToImageRequest>(DEFAULT_PARAMS);
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState<IAbloImage[]>([]);
  const [referenceImageFile, setReferenceImageFile] = useState<{ file: File; objectUrl?: string }>(
    null
  );
  const [referenceImageStrength, setReferenceImageStrength] = useState(
    DEFAULT_REFERENCE_IMAGE_STRENGTH
  );
  const [isCropDone, setIsCropDone] = useState(false);

  const toast = useToast();

  const { styleId } = options;

  const styles = [NO_STYLE, ...imageStyles];

  const onCropComplete = (_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handlePlaceArtwork = () => {
    onGeneratedImageSelected({
      id: selectedImage.id,
      options,
      url: selectedImage.url,
    });
  };

  const handleImageUploaded = (image: File) => {
    setImages([]);
    setUploadedImage({ file: image, preview: URL.createObjectURL(image) });
  };

  const handleReset = () => {
    setImages([]);
    setSelectedImage(null);

    setCroppedImage(null);

    const options = { ...DEFAULT_PARAMS };

    if (styles?.length === 1) {
      options.styleId = styles[0].id;
    }
    setOptions(options);
  };

  const handleUpdate = (updates) => setOptions({ ...options, ...updates });

  const handleSelectUploadedImage = async () => {
    try {
      const { file: croppedImage, base64 } = await getCroppedImg(
        uploadedImage.preview,
        croppedAreaPixels
      );

      setCroppedImage({
        base64,
        preview: URL.createObjectURL(croppedImage),
        file: new File([croppedImage], 'image', { type: 'image/png' }),
      });

      setUploadedImage(null);

      setCrop(DEFAULT_CROP);
      setZoom(DEFAULT_ZOOM);
    } catch (e) {
      console.error(e);

      toast({
        title: 'Error cropping image',
        description: e.message,
        status: 'error',
      });
    }
  };

  const handleGenerate = async () => {
    setImages([]);

    if (!styleId) {
      // Downsize image
      setWaiting(true);
      const uri = await downsizeImageFileToBase64(croppedImage.file);
      setWaiting(false);
      onGeneratedImageSelected({
        id: `${Date.now()}`,
        url: uri as string,
      });

      return;
    }

    setWaiting(true);

    const requestParams: IImageFileToImageRequest = {
      imageFile: croppedImage.file,
      styleId,
      referenceImageFile: referenceImageFile?.file,
      ipAdapterScale: referenceImageStrength / 100,
      samples: SAMPLES,
    };

    generateImageFromImage(requestParams, croppedImage.file.type)
      .then((images) => {
        setWaiting(false);

        setImages(images);
        setSelectedImage(images[0]);
      })
      .catch(() => {
        setWaiting(false);
      });
  };

  const onCropReferenceImageComplete = async () => {
    const { file: croppedImage } = await getCroppedImg(
      referenceImageFile.objectUrl,
      croppedAreaPixels
    );

    setReferenceImageFile({
      file: new File([croppedImage], referenceImageFile.file.name, { type: 'image/png' }),
    });

    setCroppedAreaPixels(null);
    setIsCropDone(true);
    setCrop(DEFAULT_CROP);
    setZoom(DEFAULT_ZOOM);
  };

  const handleReferenceImageFileChange = (file: File): void => {
    setReferenceImageFile(file ? { file, objectUrl: URL.createObjectURL(file) } : null);
    setCroppedAreaPixels(null);
    setIsCropDone(false);
    setCrop(DEFAULT_CROP);
    setZoom(DEFAULT_ZOOM);
  };

  const onReferenceImageCropped = (_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <Box
      p="18px 14px 26px 14px"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleGenerate();
        }
      }}
    >
      <Box>
        {images.length > 0 ? (
          <VStack>
            <ImagesPreview
              images={images}
              selectedImage={selectedImage}
              onSelectedImage={setSelectedImage}
              onPlaceArtwork={handlePlaceArtwork}
              onGenerateSimilar={handleGenerate}
              onNewArtwork={handleReset}
              buttonTitle="Place Image"
              showGenerateNewButtons={false}
              addFavorite={addFavorite}
              favorites={favorites}
              removeFavorite={removeFavorite}
            />
            <Box width="100%" height="1px" bg="#E7E7E7" m="12px 0" />
          </VStack>
        ) : (
          <>
            <H5 alignSelf="flex-start" fontWeight={700} mb="12px">
              {uploadedImage ? 'Crop Image' : 'Upload image and transform it'}
            </H5>
            {!croppedImage && (
              <Box mb="1px" w="100%">
                {uploadedImage ? (
                  <Box mb="16px" position="relative" w={`${IMAGE_WIDTH}px`} h={`${IMAGE_WIDTH}px`}>
                    <Cropper
                      image={uploadedImage.preview}
                      crop={crop}
                      cropSize={{
                        width: IMAGE_WIDTH,
                        height: IMAGE_WIDTH,
                      }}
                      zoom={zoom}
                      minZoom={0.5}
                      restrictPosition={false}
                      aspect={1}
                      objectFit="contain"
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                    />
                  </Box>
                ) : (
                  <Image src={croppedImage} />
                )}
              </Box>
            )}
          </>
        )}
      </Box>
      {!uploadedImage && (
        <VStack align="flex-start" spacing="16px">
          {croppedImage ? (
            <VStack>
              <Box
                borderRadius="50%"
                border="3px solid #064AC4"
                onClick={() => setCroppedImage(null)}
                position="relative"
              >
                <Image src={croppedImage.preview} w="85px" borderRadius="50%" />
                <Box position="absolute" top="-8px" right="-8px">
                  <IconDelete />
                </Box>
              </Box>
              <Text color="#1A1A1A" fontSize="11px" align="center" w="60px">
                My photo
              </Text>
            </VStack>
          ) : (
            <ImageUpload onFilesSelected={(files) => handleImageUploaded(files[0])} />
          )}
          <Box mt={{ base: 0, lg: '10px' }} w="100%">
            <StyleSelector
              styles={styles}
              selectedStyleId={styleId}
              onSelectedStyle={(styleId) => {
                handleUpdate({ styleId });
              }}
              referenceImageFile={referenceImageFile?.file}
              onReferenceImageFileChange={handleReferenceImageFileChange}
            />
          </Box>
        </VStack>
      )}
      {referenceImageFile && !isCropDone && (
        <CropTool
          crop={crop}
          imageFile={referenceImageFile.objectUrl}
          onCropComplete={onReferenceImageCropped}
          onCropChange={setCrop}
          zoom={zoom}
          onZoomChange={setZoom}
          onSetCrop={onCropReferenceImageComplete}
        />
      )}

      {referenceImageFile && (
        <Box mt="16px">
          <AbloSlider
            defaultValue={referenceImageStrength}
            min={1}
            max={100}
            step={1}
            height="2px"
            onChange={setReferenceImageStrength}
            value={referenceImageStrength}
            width="100%"
            label="Reference Image Strength"
          />
        </Box>
      )}

      {((!referenceImageFile && options.styleId !== undefined) ||
        (referenceImageFile && isCropDone)) && (
        <HStack mt="22px">
          {uploadedImage && !croppedImage && (
            <>
              <Box w="100%">
                <ImageUpload
                  onFilesSelected={(files) => handleImageUploaded(files[0])}
                  customButtonContent={
                    <Button variant="outlined" title={'Choose Image'} w="100%" />
                  }
                />
              </Box>
              <Button onClick={handleSelectUploadedImage} title={'Next'} w="100%" />
            </>
          )}
          {croppedImage && (
            <Button
              disabled={!styleId || waiting}
              iconRight={styleId ? <CreditCostIcon cost={actionCost * SAMPLES} /> : null}
              onClick={handleGenerate}
              title={images.length ? 'Re-generate' : styleId ? 'Generate' : 'Place'}
              icon={waiting ? <Spinner /> : null}
              w="100%"
            />
          )}
        </HStack>
      )}
    </Box>
  );
}
