import {
  FormControl,
  FormErrorMessage,
  NumberInput as ChakraNumberInput,
  InputGroup,
  InputRightElement,
  Button,
  Input,
} from '@chakra-ui/react';

import FormLabel from './FormLabel';
import IconEyeClosed from '../icons/IconEyeClosed';
import IconEye from '../icons/IconEye';
import { useState } from 'react';
import InputWithMaxCharacters from './InputWithMaxCharacters';

const PasswordInput = (props) => {
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input placeholder="Enter password" {...props} type={show ? 'text' : 'password'} />
      <InputRightElement top="5px">
        <Button
          bg="transparent"
          h="100%"
          minW="none"
          size="sm"
          onClick={handleClick}
          w="20px"
          tabIndex={-1}
        >
          {show ? <IconEyeClosed /> : <IconEye />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

const NumberInput = (props) => <ChakraNumberInput {...props}></ChakraNumberInput>;

function FormInput(props) {
  const { error, description, isNumber, isPassword, maxCharacters, name, ...rest } = props;

  const inputProps = {
    ...rest,
    isInvalid: error,
    placeholder: name,
  };

  return (
    <FormControl variant="floating" isInvalid={error}>
      {(isNumber && <NumberInput {...inputProps} />) ||
        (isPassword && <PasswordInput {...inputProps} />) ||
        (maxCharacters && (
          <InputWithMaxCharacters maxCharacters={maxCharacters} {...inputProps} />
        )) || <Input {...inputProps} />}
      <FormLabel>{name}</FormLabel>
      {error ? (
        <FormErrorMessage color="red.600" fontSize="12px">
          {error}
        </FormErrorMessage>
      ) : null}
      {description && !error ? description : null}
    </FormControl>
  );
}

export default FormInput;
