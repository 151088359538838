import { useEffect, useState } from 'react';

import { Design } from '@/lib/types';
import ImagePreviewGallery from '@/components/images/ImagePreviewGallery';
import { findIndex } from 'lodash';

interface DesignPreviewGalleryProps {
  design: Design;
  onZoom?: () => void;
  onSelectedIndex?: (index: number) => void;
}

const DesignPreviewGallery = ({ design, onZoom, onSelectedIndex }: DesignPreviewGalleryProps) => {
  const [selectedImageId, setSelectedImageId] = useState<string>(null);

  useEffect(() => {
    if (!design) {
      return;
    }
    setSelectedImageId(design.sides[0]?.id);
  }, [design]);

  const images = [
    ...design.sides.reduce((result, { designImage, previewImage, id }) => {
      const newItems = [{ url: previewImage, id, isDesignImage: false }];

      if (designImage) {
        newItems.push({ isDesignImage: true, url: designImage, id: `${id}-designImage` });
      }

      return [...result, ...newItems];
    }, []),
    ...(design.mockupImages || []),
  ];

  const selectedImage = images.find(({ id }) => id === selectedImageId);

  return (
    <ImagePreviewGallery
      images={images}
      onSelectedImageId={(id) => {
        setSelectedImageId(id);

        if (onSelectedIndex) {
          onSelectedIndex(findIndex(images, (image) => image.id === id));
        }
      }}
      selectedImageId={selectedImage?.id}
      onZoom={onZoom}
    />
  );
};

export default DesignPreviewGallery;
