import { Icon } from '@chakra-ui/react';

const IconAttribution = ({ color }) => (
  <Icon
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9474 1.88836C11.166 1.10696 10.1062 0.667969 9.00114 0.667969C7.89607 0.667969 6.83626 1.10696 6.05486 1.88836C5.27346 2.66976 4.83447 3.72957 4.83447 4.83464C4.83447 5.9397 5.27346 6.99951 6.05486 7.78091C6.83626 8.56231 7.89607 9.0013 9.00114 9.0013C10.1062 9.0013 11.166 8.56231 11.9474 7.78091C12.7288 6.99951 13.1678 5.9397 13.1678 4.83464C13.1678 3.72957 12.7288 2.66976 11.9474 1.88836ZM7.528 3.3615C7.9187 2.9708 8.44861 2.7513 9.00114 2.7513C9.55367 2.7513 10.0836 2.9708 10.4743 3.3615C10.865 3.7522 11.0845 4.2821 11.0845 4.83464C11.0845 5.38717 10.865 5.91707 10.4743 6.30777C10.0836 6.69847 9.55367 6.91797 9.00114 6.91797C8.44861 6.91797 7.9187 6.69847 7.528 6.30777C7.1373 5.91707 6.91781 5.38717 6.91781 4.83464C6.91781 4.2821 7.1373 3.7522 7.528 3.3615ZM9.0013 10.0391C11.7826 10.0391 17.3346 11.4245 17.3346 14.2057V17.3307H15.3555V15.3516V14.2057C15.3555 13.5391 12.0951 12.0182 9.0013 12.0182C5.90755 12.0182 2.64714 13.5391 2.64714 14.2057V15.3516V17.3307H0.667969V14.2057C0.667969 11.4245 6.22005 10.0391 9.0013 10.0391Z"
      fill={color || '#A0AEC0'}
    />
  </Icon>
);

export default IconAttribution;
