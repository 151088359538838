import React, { useEffect, useState } from 'react';

import {
  Button,
  Box,
  Flex,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Show,
} from '@chakra-ui/react';

import ROUTES from '@/layouts/admin/routes';

import IconToggle from './IconToggle';
import IconDrawerMenu from '../icons/IconDrawerMenu';
import CreditsPill from './CreditsPill';
import SidebarLink from './SidebarLink';
import Separator from './components/Separator';
import { Route, User } from '../types';
import { DESIGN_STUDIO, HOME, MERCH_SHOP } from '@/constants';

const HAS_SEEN_FIRST_TIME_USER_HINT = 'HAS_SEEN_DEMO_HINT';

type SidebarContentProps = {
  isCollapsed?: boolean;
  onChangeCollapsed?: (isCollapsed: boolean) => void;
  onRouteClick?: (route: Route) => void;
  me?: User;
};

const SidebarContent = ({
  isCollapsed,
  onChangeCollapsed,
  me,
  onRouteClick,
}: SidebarContentProps) => {
  const [isFirstTimeUserHintVisible, setFirstTimeUserHintVisible] = useState(false);

  const handleDismissFirstTimeUserHint = () => {
    localStorage.setItem(HAS_SEEN_FIRST_TIME_USER_HINT, 'true');

    setFirstTimeUserHintVisible(false);
  };

  useEffect(() => {
    if (!localStorage.getItem(HAS_SEEN_FIRST_TIME_USER_HINT)) {
      setFirstTimeUserHintVisible(true);
    }
  }, []);

  const routesToUse = me
    ? ROUTES
    : ROUTES.filter(({ name }) => [HOME, MERCH_SHOP, DESIGN_STUDIO].includes(name));

  return (
    <Flex borderRight="1px solid #E2E8F0" direction="column" height="100%" pt="20px">
      {me ? (
        <Flex
          justify="space-between"
          pl="20px"
          pb={{ base: '15px', md: 0 }}
          pr={isCollapsed ? '20px' : '15px'}
        >
          {!isCollapsed ? <CreditsPill /> : null}
          <Show above="lg">
            <Button bg="transparent" onClick={() => onChangeCollapsed(!isCollapsed)} padding={0}>
              <IconToggle isRotated={isCollapsed} />
            </Button>
          </Show>
        </Flex>
      ) : null}
      {me ? <Separator px="20px" /> : null}
      <VStack align="flex-start" px="20px" spacing="4px">
        {routesToUse
          .filter(({ notInMenu }) => !notInMenu)
          .map((route, index) => (
            <SidebarLink
              key={index}
              isCollapsed={isCollapsed}
              route={route}
              isFirstTimeUserHintVisible={isFirstTimeUserHintVisible}
              onDismissFirstTimeUserHint={handleDismissFirstTimeUserHint}
              onRouteClick={onRouteClick}
              user={me}
            />
          ))}
        <Separator />
      </VStack>
    </Flex>
  );
};

interface SidebarProps extends SidebarContentProps {}

const Sidebar = ({ isCollapsed, onChangeCollapsed, me, onRouteClick }: SidebarProps) => {
  return (
    <Box
      bg="#FFFFFF"
      transition="0.2s linear"
      w={`${isCollapsed ? 83 : 247}px`}
      h="calc(100vh - 80px)"
      overflowX="hidden"
      boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      display={{ sm: 'none', lg: 'block' }}
    >
      <SidebarContent
        isCollapsed={isCollapsed}
        onChangeCollapsed={onChangeCollapsed}
        me={me}
        onRouteClick={onRouteClick}
      />
    </Box>
  );
};

interface SidebarResponsiveProps {
  me?: User;
  onRouteClick?: (route: Route) => void;
}

export const SidebarResponsive = ({ me, onRouteClick }: SidebarResponsiveProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Flex display={{ sm: 'flex', lg: 'none' }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <IconDrawerMenu />
      </Flex>
      <Drawer isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef} placement="left">
        <DrawerOverlay />
        <DrawerContent w="285px" maxW="285px" bg="#FFFFFF">
          <DrawerCloseButton
            zIndex="3"
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <SidebarContent me={me} onRouteClick={onRouteClick} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Sidebar;
