import { Icon } from '@chakra-ui/react';

const IconStar = () => (
  <Icon
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3656 6.55373L10.3553 1.55176L7.34509 6.55373L1.65771 7.87094L5.48466 12.2795L4.9799 18.0956L10.3553 15.8183L15.7307 18.0956L15.226 12.2795L19.0529 7.87094L13.3656 6.55373ZM16.2863 8.76988L12.408 7.87167L10.3553 4.46079L8.30262 7.87167L4.42437 8.76988L7.03399 11.7761L6.68979 15.7421L10.3553 14.1892L14.0208 15.7421L13.6767 11.7761L16.2863 8.76988Z"
      fill="black"
    />
  </Icon>
);

export default IconStar;
