export const breakpoints = {
  sm: '320px',
  '2sm': '441px',
  '3sm': '600px',
  md: '768px',
  lg: '1030px',
  xl: '1420px',
  '2xl': '1600px',
  '3xl': '1920px',
};
