import { useEffect, useState } from 'react';
import { Box, Center, HStack, VStack } from '@chakra-ui/react';

import { Design } from '@/lib/types';
import DesignPreview from '@/views/demo/pages/design/components/DesignSideImage';

interface OrderDesignPreviewGalleryProps {
  design: Design;
}

const OrderDesignPreviewGallery = ({ design }: OrderDesignPreviewGalleryProps) => {
  const [selectedSideIndex, setSelectedSideIndex] = useState<number>(0);

  const { sides, template } = design;

  useEffect(() => {
    if (!template) {
      return;
    }
    setSelectedSideIndex(0);
  }, [template]);

  return (
    sides?.length && (
      <HStack
        align="flex-start"
        bg="#FFFFFF"
        borderRadius="10px"
        justify={{ base: 'space-between', md: 'flex-start' }}
        h="100%"
        spacing="5px"
        w={{ base: '100%', md: 'auto' }}
      >
        <Box position="relative">
          <DesignPreview
            design={design}
            sideName={sides[selectedSideIndex].templateSide.name}
            imageWidth={236}
            height={236}
            width={236}
          />
        </Box>
        {sides.length > 1 ? (
          <VStack spacing={{ base: '4px' }}>
            {sides.map((side, index) => (
              <Center
                as="button"
                borderRadius={4}
                borderColor="secondaryDarkGray.600"
                borderWidth={selectedSideIndex === index ? '1px' : 0}
                key={side.templateSide.name}
                onClick={() => setSelectedSideIndex(index)}
              >
                <DesignPreview
                  design={design}
                  sideName={side.templateSide.name}
                  imageWidth={56}
                  isClickable
                  height="56px"
                  width="56px"
                />
              </Center>
            ))}
          </VStack>
        ) : (
          <Box w="64px" />
        )}
      </HStack>
    )
  );
};

export default OrderDesignPreviewGallery;
