import { HStack, Text } from '@chakra-ui/react';
import IconWarning from '@/components/icons/IconWarning';

const LOW_RISK = 33;
const MODERATE_RISK = 66;

type IpInfringementAlertProps = {
  riskScore: number;
};

const IpInfringementAlert = ({ riskScore }: IpInfringementAlertProps) => {
  let color = null;

  let risk = null;
  if (riskScore && riskScore > 0) {
    if (riskScore <= LOW_RISK) {
      color = '#064AC4';
      risk = 'Low';
    } else if (riskScore < MODERATE_RISK) {
      color = '#DD6B20';
      risk = 'Moderate';
    } else {
      color = '#B80A0A';
      risk = 'High';
    }
  }
  return (
    risk && (
      <HStack spacing="6px">
        <IconWarning color={color} />
        <Text color={color} textStyle="bodySuperSmall">
          <Text as="b" fontWeight={700}>
            {risk} risk.
          </Text>{' '}
          These images may be subject to copyright checks & approvals by manufacturing partners.
        </Text>
        <div></div>
      </HStack>
    )
  );
};

export default IpInfringementAlert;
