import { Icon } from '@chakra-ui/react';

const IconHeart = () => (
  <Icon
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8283 4.70959L16.8277 4.70901C16.4185 4.30782 15.9359 3.98901 15.4064 3.7699L15.4054 3.76949C14.8559 3.54125 14.2666 3.42442 13.6716 3.42579L13.6699 3.4258C12.833 3.4258 12.0179 3.65488 11.3103 4.08661L11.3102 4.08662C11.1409 4.18991 10.9806 4.30302 10.829 4.42595C10.5537 4.64931 10.1595 4.64931 9.88411 4.42595C9.73255 4.30302 9.57224 4.18991 9.40291 4.08662L9.40289 4.08661C8.6952 3.65488 7.88009 3.4258 7.04322 3.4258C6.43926 3.4258 5.85748 3.54115 5.30775 3.7695L5.30694 3.76983C4.77527 3.98995 4.29687 4.30603 3.88519 4.70923L3.88417 4.71023C3.47628 5.10822 3.15044 5.58237 2.92512 6.10582L16.8283 4.70959ZM16.8283 4.70959C17.2361 5.10842 17.5622 5.58291 17.7883 6.10653C18.0226 6.65111 18.1416 7.22699 18.1399 7.82052V7.82265C18.1399 8.37789 18.0265 8.97276 17.7883 9.5957L17.7876 9.5974M16.8283 4.70959L17.7876 9.5974M6.32898 14.1057L6.32906 14.1058C7.21983 14.9284 8.1092 15.6264 8.78668 16.1251C9.12485 16.3741 9.40875 16.5723 9.6117 16.7105C9.71315 16.7796 9.79417 16.8335 9.85137 16.8711C9.91163 16.9107 9.9403 16.9288 9.93958 16.9284L9.95147 16.9357L9.9514 16.9358L10.3556 17.195L10.7598 16.9358L10.7599 16.9357C10.8221 16.8958 12.5939 15.7555 14.3824 14.1055C15.4578 13.1127 16.3064 12.149 16.9136 11.2439L6.32898 14.1057ZM6.32898 14.1057C5.25354 13.1129 4.40498 12.1493 3.79779 11.2441M6.32898 14.1057L3.79779 11.2441M17.7876 9.5974C17.5904 10.117 17.2974 10.6709 16.9136 11.2438L17.7876 9.5974ZM3.79779 11.2441C3.4149 10.6726 3.12293 10.1179 2.92289 9.5957L3.79779 11.2441ZM2.57129 7.82265C2.57129 7.22744 2.69067 6.65063 2.92509 6.10587L2.92274 9.59533C2.68461 8.97252 2.57129 8.37778 2.57129 7.82265Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </Icon>
);

export default IconHeart;
