import { Text, Box, VStack, Center, HStack } from '@chakra-ui/react';

import Button from '@/components/button';
import ModalContainer from '@/components/modals/ModalContainer';
import IconAttribution from './icons/IconAttribution';
import { H3 } from '@/components/typography/Headings';
import IconCommercialUse from './icons/IconCommercialUse';
import IconRemixing from './icons/IconRemixing';
import IconRoyaltySharing from './icons/IconRoyaltySharing';
import IconAiTraining from './icons/IconAiTraining';
import { LicenseTerm } from '@/components/types/story-protocol';
import { getLicenseType, LICENSE_PROPERTIES } from './licenses';

enum LICENSE_TERMS_PROPERTIES {
  ATTRIBUTION = 'attribution',
  COMMERCIAL_USE = 'commercialUse',
  REMIXING = 'remixing',
  ROYALTY_SHARING = 'royaltySharing',
  AI_TRAINING = 'aiTraining',
}

const ICON_BOX_SIZE = 36;

const getAttributionTerms = (isRequired: boolean) => ({
  title: `Attribution ${isRequired ? '' : 'is not '}required`,
  description: `People ${
    isRequired ? 'must ' : 'don’t need to '
  }give credit to the original work in their commercial application (eg. merch).`,
});

const getCommercialUseTerms = (isAllowed: boolean) => ({
  title: isAllowed ? 'Commercial use' : 'Non-commercial use only',
  description: `You ${isAllowed ? 'can ' : 'can’t '}make money from using the original IP Asset`,
});

const getRemixingTerms = (isAllowed: boolean) => ({
  title: isAllowed ? 'Remixing allowed' : 'No remixes allowed',
  description:
    'A remix or a derivative, in the context of licensable art, refers to a new work created by altering, transforming, or building upon an existing piece of original art.',
});

const getRoyaltySharingTerms = (hasSharing: boolean) => ({
  title: hasSharing ? 'Royalty sharing' : 'No royalty sharing',
  description: `Revenue (from any source, original & derivative) is ${
    hasSharing ? 'permitted' : 'not permitted'
  } by this license.`,
  isHighlighted: hasSharing,
});

const getAiTrainingTerms = () => ({
  title: 'AI training allowed',
  isHighlighted: true,
});

const getTermProperties = (
  type: LICENSE_TERMS_PROPERTIES,
  licenseTerm: LicenseTerm
): { title: string; description?: string; isHighlighted: boolean } => {
  const {
    commercialAttribution,
    commercialRevShare,
    commercialUse,
    derivativesAllowed,
    derivativesAttribution,
  } = licenseTerm.terms;

  if (type === LICENSE_TERMS_PROPERTIES.ATTRIBUTION) {
    const needsAttribution = commercialUse ? commercialAttribution : derivativesAttribution;

    return {
      ...getAttributionTerms(needsAttribution),
      isHighlighted: needsAttribution,
    };
  }

  if (type === LICENSE_TERMS_PROPERTIES.COMMERCIAL_USE) {
    return {
      ...getCommercialUseTerms(commercialUse),
      isHighlighted: commercialUse,
    };
  }

  if (type === LICENSE_TERMS_PROPERTIES.REMIXING) {
    return {
      ...getRemixingTerms(derivativesAllowed),
      isHighlighted: derivativesAllowed,
    };
  }

  if (type === LICENSE_TERMS_PROPERTIES.ROYALTY_SHARING) {
    return getRoyaltySharingTerms(commercialRevShare > 0);
  }

  return getAiTrainingTerms();
};

const TERMS = [
  {
    icon: IconAttribution,
    type: LICENSE_TERMS_PROPERTIES.ATTRIBUTION,
  },
  {
    icon: IconCommercialUse,
    type: LICENSE_TERMS_PROPERTIES.COMMERCIAL_USE,
  },
  {
    icon: IconRemixing,
    type: LICENSE_TERMS_PROPERTIES.REMIXING,
  },
  {
    icon: IconRoyaltySharing,
    type: LICENSE_TERMS_PROPERTIES.ROYALTY_SHARING,
  },
  {
    icon: IconAiTraining,
    type: LICENSE_TERMS_PROPERTIES.AI_TRAINING,
  },
];

interface IpAssetLicenseModalProps {
  licenseTerm: LicenseTerm;
  onClose: () => void;
  onStart: () => void;
}

const IpAssetLicenseModal = ({ licenseTerm, onClose, onStart }: IpAssetLicenseModalProps) => {
  const licenseType = getLicenseType(licenseTerm);

  const licenseProperties = LICENSE_PROPERTIES[licenseType];

  const { primaryColor, secondaryColor, caption, title, description } = licenseProperties;

  return (
    <ModalContainer onClose={onClose}>
      <Box h="100%" pb="30px">
        <Box h="100%" overflowY="auto">
          <Center
            backgroundColor={secondaryColor}
            border="1px solid"
            borderColor={primaryColor}
            borderRadius="3px"
            fontSize="9px"
            h="16px"
            mb="12px"
            p="0 6px"
            w="max-content"
          >
            <Text color={primaryColor} fontWeight={500}>
              {caption.toUpperCase()}
            </Text>
          </Center>
          <H3 maxW="calc(100% - 40px)" mb="18px">
            {title}
          </H3>
          <Text color="secondaryDarkGray.600" mb="8px" textStyle="body">
            License Summary
          </Text>
          <Text mb="20px" textStyle="body">
            {description}
          </Text>
          <Text color="secondaryDarkGray.600" mb="14px" textStyle="body">
            License Terms
          </Text>
          <VStack align="flex-start" mb={{ base: '20px', md: 0 }} spacing="15px">
            {TERMS.map((term, index) => {
              const { icon: Icon, type } = term;

              const { title, description, isHighlighted } = getTermProperties(type, licenseTerm);

              return (
                <HStack align={description ? 'flex-start' : 'center'} key={index} spacing="15px">
                  <Center
                    bg={isHighlighted ? secondaryColor : 'transparent'}
                    borderRadius="4px"
                    border="1px solid"
                    borderColor={isHighlighted ? primaryColor : 'secondaryGray.400'}
                    h={`${ICON_BOX_SIZE}px`}
                    w={`${ICON_BOX_SIZE}px`}
                  >
                    <Icon color={isHighlighted && primaryColor} />
                  </Center>
                  <Box flex={1}>
                    <Text fontWeight={600} mb="4px" textStyle="body">
                      {title}
                    </Text>
                    <Text textStyle="body">{description}</Text>
                  </Box>
                </HStack>
              );
            })}
          </VStack>
        </Box>
      </Box>
      <HStack
        bottom={{ base: '20px', md: '12px' }}
        left={{ base: '16px', md: 0 }}
        right="16px"
        position={{ base: 'absolute', md: 'relative' }}
        w="auto"
      >
        <Button onClick={onStart} w="100%">
          Choose Template
        </Button>
      </HStack>
    </ModalContainer>
  );
};

export default IpAssetLicenseModal;
