import { Icon } from '@chakra-ui/react';

const IconRemixing = ({ color }) => (
  <Icon
    width="22px"
    height="20px"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6097 3.09573L14.8152 4.01973C13.2582 6.45058 9.76239 9.12273 7.35211 9.12273L1.58653 9.12273C0.893533 9.12273 0.521534 9.43387 0.521534 10.0064C0.521534 10.5692 0.893533 10.8807 1.58653 10.8807L7.35168 10.8807C9.73239 10.8807 13.1875 13.5019 14.7848 15.943L13.6191 16.7967C13.0371 17.2184 13.1674 17.731 13.8205 17.9419L17.517 19.2074C18.0591 19.3883 18.441 19.0969 18.4311 18.5444L18.3707 14.6273C18.3604 13.954 17.9185 13.6729 17.3464 14.0847L16.2312 14.8982C14.574 12.5376 11.6207 10.318 10.104 10.0167L10.104 9.98673C11.6305 9.68501 14.574 7.4753 16.2312 5.11473L17.2555 5.89815C17.8182 6.34001 18.27 6.06873 18.3004 5.39587L18.471 1.47873C18.501 0.925868 18.1294 0.624582 17.577 0.785297L13.8505 1.95101C13.8505 1.95101 13.0375 2.65387 13.6097 3.09573Z"
      fill={color || '#A0AEC0'}
    />
  </Icon>
);

export default IconRemixing;
