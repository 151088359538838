import { Icon } from '@chakra-ui/react';

const IconFront = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.325 18.0438H10.965V16.5438H14.825V7.24376H18.265V4.85376H10.965V3.35376H19.765V8.74376H16.325V18.0438Z"
      fill="black"
    />
    <path
      d="M10.965 18.0438H5.60498V8.74376H2.16498V3.35376H10.965V4.85376H3.66498V7.24376H7.10498V16.5438H10.965V18.0438Z"
      fill="black"
    />
    <path
      d="M10.965 7.71387C9.30497 7.71387 7.96497 6.36387 7.96497 4.71387V3.71387H13.975V4.71387C13.975 6.37387 12.625 7.71387 10.975 7.71387H10.965ZM8.96497 4.71387C8.96497 5.81387 9.86497 6.71387 10.965 6.71387C12.065 6.71387 12.965 5.81387 12.965 4.71387H8.96497Z"
      fill="black"
    />
  </Icon>
);

export default IconFront;
