import {
  Button,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import IconClear from './IconClear';
import IconSearch from './IconSearch';
import { useState } from 'react';

interface SearchProps extends Omit<InputGroupProps, 'onChange' | 'value'> {
  onChange: (value?: string) => void;
  value: string;
  width?: string | number;
}

const Search = ({ onChange, value, width, ...rest }: SearchProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputGroup size="md" width={width || 320} {...rest}>
      <Input
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder="Search"
        h="46px"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
      <InputLeftElement h="100%" bg="transparent">
        <IconSearch color={(isFocused || value) && '#212529'} />
      </InputLeftElement>
      {value ? (
        <InputRightElement>
          <Button
            bg="transparent"
            h="100%"
            minW="none"
            size="sm"
            onClick={() => onChange('')}
            w="20px"
            tabIndex={-1}
          >
            <IconClear />
          </Button>
        </InputRightElement>
      ) : null}
    </InputGroup>
  );
};

export default Search;
