import { DESIGN_STUDIO } from '@/constants';
import DesignPreviewPage from '@/views/demo/pages/design/DesignPreviewPage';
import HomeGuest from '@/views/home/HomeGuest';
import ProductDetailsGuest from '@/views/product/ProductDetailsGuest';

import ProductsPageGuest from '@/views/products/ProductsPageGuest';
import IconGenerateArt from './icons/IconGenerateArt';
import IconGenerateArtActive from './icons/IconGenerateArtActive';
import SelectTemplatePageGuest from '@/views/template/SelectTemplatePageGuest';

const PUBLIC_ROUTES = [
  {
    path: '/brands/:idOrSlug',
    component: ProductsPageGuest,
  },
  {
    name: DESIGN_STUDIO,
    defaultPath: '/designs/new',
    path: '/designs/new',
    icon: <IconGenerateArt />,
    iconActive: <IconGenerateArtActive />,
    component: SelectTemplatePageGuest,
  },
  {
    path: '/designs/:id',
    component: DesignPreviewPage,
  },
  {
    path: '/',
    exact: true,
    component: HomeGuest,
  },
  {
    path: '/products',
    exact: true,
    component: ProductsPageGuest,
  },
  {
    path: '/products/:id',
    exact: true,
    component: ProductDetailsGuest,
  },
];

export default PUBLIC_ROUTES;
