import { IpAssetResponse, LicenseTerm } from '@/components/types/story-protocol';
import Config from '@/config';

const STORY_PROTOCOL_API = 'https://api.storyapis.com/api/v3';

const HEADERS = {
  'X-Api-Key': Config.STORY_PROTOCOL_API_KEY,
  'X-Chain': Config.STORY_PROTOCOL_CHAIN_ID,
};

export const getIPAsset = (assetId: string): Promise<IpAssetResponse> =>
  fetch(`${STORY_PROTOCOL_API}/assets/${assetId}`, {
    headers: HEADERS,
  }).then((response) => response.json());

export const getLicenseTermsForIp = (assetId: string): Promise<IpAssetResponse> =>
  fetch(`${STORY_PROTOCOL_API}/licenses/ip/terms/${assetId}`, {
    headers: HEADERS,
  }).then((response) => response.json());

export const getLicenseTerm = (licenseTermId: string): Promise<LicenseTerm> =>
  fetch(`${STORY_PROTOCOL_API}/licenses/terms/${licenseTermId}`, {
    headers: HEADERS,
  })
    .then((response) => response.json())
    .then(({ data }) => data);
