import { Icon } from '@chakra-ui/react';

const IconRectangle = () => (
  <Icon
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.17975 3.02734H3.06201V6.14508H6.17975V3.02734ZM2.06201 2.02734V7.14508H7.17975V2.02734H2.06201Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.17975 14.4967H3.06202V17.6144H6.17975V14.4967ZM2.06202 13.4967V18.6144H7.17975V13.4967H2.06202Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6489 3.02734H14.5312V6.14508H17.6489V3.02734ZM13.5312 2.02734V7.14508H18.6489V2.02734H13.5312Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6489 14.4967H14.5312V17.6144H17.6489V14.4967ZM13.5312 13.4967V18.6144H18.6489V13.4967H13.5312Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.93794 13.7017V7.00699H5.43794V13.7017H3.93794Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3401 13.7017V7.00699H16.8401V13.7017H15.3401Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.14585 3.90327H13.632V5.40327H7.14585V3.90327Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.14585 15.3056H13.632V16.8056H7.14585V15.3056Z"
      fill="black"
    />
  </Icon>
);

export default IconRectangle;
