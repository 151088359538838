import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { Design, getDesignPreviewImage } from '@/lib';
import CreatorAvatar from '@/views/home/components/CreatorAvatar';

import {
  Center,
  Flex,
  Hide,
  HStack,
  Image,
  Show,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import IpHistoryPreviewImageHover from './IpHistoryPreviewImageHover';

interface IpHistoryCardProps {
  isCurrent?: boolean;
  isOriginal?: boolean;
  design: Design;
}

const IpHistoryCard = ({ design, isCurrent, isOriginal }: IpHistoryCardProps) => {
  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(true);
  const [designPreviewImage, setDesignPreviewImage] = useState<string>(null);

  const [isHovered, setHovered] = useState(false);

  const [isThumbnailHovered, setThumnbailHovered] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    getDesignPreviewImage(design)
      .then(({ previewImage }) => {
        setDesignPreviewImage(previewImage);

        setIsLoadingPreviewImage(false);
      })
      .catch((e) => console.log('E', e));
  }, []);

  const handleGoToDesign = () => {
    window.open(`${window.location.origin}/products/${design.id}`, isMobile ? '_self' : '_blank');
  };

  const { createdAt, name, user } = design;

  return (
    <Flex
      alignItems="center"
      bg={isCurrent || isOriginal ? 'lightGray.400' : ''}
      borderColor={
        isHovered
          ? isCurrent || isOriginal
            ? 'brand.600'
            : 'secondaryDarkGray.600'
          : 'borderLight'
      }
      borderRadius={{ base: '4px', md: '3px 4px 4px 3px' }}
      borderWidth="1px"
      borderLeftWidth="3px"
      borderLeftColor={
        isCurrent || isOriginal ? 'brand.600' : isHovered ? 'secondaryDarkGray.600' : 'borderLight'
      }
      cursor="pointer"
      justifyContent="space-between"
      h={{ base: '74px', md: '60px' }}
      onClick={handleGoToDesign}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      pr={{ base: '10px', md: '16px' }}
      py="8px"
      position="relative"
      w="100%"
    >
      {isHovered && !isMobile ? (
        <Center
          bg="#FFFFFF"
          border="1px solid"
          borderColor="borderLight"
          borderRadius="4px"
          h="24px"
          padding="4px 7px"
          position="absolute"
          left="calc(50% - 61px)"
          top="-12px"
          w="122px"
        >
          <Text textStyle="bodySuperSmall">Open in a New Tab</Text>
        </Center>
      ) : null}
      <HStack
        position="relative"
        spacing="10px"
        onMouseEnter={() => setThumnbailHovered(true)}
        onMouseLeave={() => setThumnbailHovered(false)}
      >
        {isLoadingPreviewImage || !designPreviewImage ? null : (
          <Image
            src={designPreviewImage}
            h={{ base: '56px', md: '44px' }}
            w={{ base: '60px', md: '47px' }}
          />
        )}
        {isThumbnailHovered && !isMobile ? (
          <IpHistoryPreviewImageHover imageUrl={designPreviewImage} />
        ) : null}
        {isLoadingPreviewImage ? (
          <Center
            h="44px"
            w="47px"
            bg={isLoadingPreviewImage ? 'transparent' : 'black'}
            opacity="0.5"
          >
            <LoadingSpinner />
          </Center>
        ) : null}
        <VStack align="flex-start" gap="4px">
          <Text
            fontWeight={600}
            maxW={{ base: '200px', md: 'none' }}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            textStyle="bodySmall"
          >
            {name}
          </Text>
          <CreatorAvatar avatarSize={14} fontSize={isMobile ? 12 : 13} user={user} />
          <Hide above="md">
            <Text fontSize="11px">{format(parseISO(createdAt), 'dd/MM/yy')}</Text>
          </Hide>
        </VStack>
      </HStack>
      <HStack alignSelf={{ base: 'flex-end', md: 'center' }} spacing="20px">
        {isCurrent || isOriginal ? (
          <Center
            borderRadius={{ base: '3px', md: '4px' }}
            bg="transparent"
            border="1px solid"
            borderColor="brand.600"
            p={{ base: '0 4px', md: '2px 3px' }}
            textTransform="uppercase"
          >
            <Text
              color="brand.600"
              fontSize={{ base: '10px', md: '12px' }}
              textStyle="bodySuperSmall"
            >
              {isCurrent ? 'Current' : 'Original'}
            </Text>
          </Center>
        ) : null}
        <Show above="md">
          <Text textStyle="bodySuperSmall">{format(parseISO(createdAt), 'dd/MM/yy')}</Text>
        </Show>
      </HStack>
    </Flex>
  );
};

export default IpHistoryCard;
