import { Canvas, CanvasObject, Template } from '@/lib/types';
import { Button, HStack, useOutsideClick } from '@chakra-ui/react';

import { useRef } from 'react';

const BUTTON_SIZE = 20;
const SELECTED_BORDER_WIDTH = 2;

const BUTTON_SELECTED_SIZE = BUTTON_SIZE + 2 * SELECTED_BORDER_WIDTH;

const isLightColor = (colorName) => ['Blanco', 'White'].includes(colorName);

const ButtonSelectColor = (props) => (
  <Button
    h={`${BUTTON_SIZE}px`}
    flexShrink={0}
    padding="0"
    w={`${BUTTON_SIZE}px`}
    borderRadius="50%"
    minWidth="auto"
    {...props}
  />
);

interface GraphicsToolsProps {
  canvas: Canvas;
  onCloseColorPicker: () => void;
  isColorPickerVisible: boolean;
  template: Template;
  selectedVariantId: string;
  onSelectedVariant: (variantId: string) => void;
  onObjectAdded: (object: CanvasObject) => void;
}

const GraphicsTools = ({
  isColorPickerVisible,
  onCloseColorPicker,
  template,
  selectedVariantId,
  onSelectedVariant,
}: GraphicsToolsProps) => {
  const wrapperRef = useRef(null);

  useOutsideClick({
    ref: wrapperRef,
    handler: (event) => {
      if ((event.target as Element)?.closest('.graphics-toggle-button')) {
        return;
      }

      // If clicked outside the button and wrapperRef, close the pickers
      onCloseColorPicker();
    },
  });

  const { colors } = template;

  return (
    <div ref={wrapperRef}>
      {isColorPickerVisible ? (
        <HStack bg="#FFFFFF" border="1px solid #DDDDDD" borderRadius="43px" p="4px 7px">
          {colors.map(({ id, name, hex }) => {
            const isSelected = selectedVariantId === id;

            return (
              <ButtonSelectColor
                bg={hex}
                border={
                  isSelected
                    ? `${SELECTED_BORDER_WIDTH}px solid #064AC4`
                    : isLightColor(name)
                    ? `${SELECTED_BORDER_WIDTH}px solid #EEEEEE`
                    : 'none'
                }
                h={`${isSelected ? BUTTON_SELECTED_SIZE : BUTTON_SIZE}px`}
                w={`${isSelected ? BUTTON_SELECTED_SIZE : BUTTON_SIZE}px`}
                key={id}
                onClick={(e) => {
                  e.stopPropagation();

                  onSelectedVariant(id);
                }}
              />
            );
          })}
        </HStack>
      ) : null}
    </div>
  );
};

export default GraphicsTools;
