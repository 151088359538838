export const renderPath = (
  path: [string, number, number][],
  width: number,
  height: number,
  baseWidth: number,
  ctx
) => {
  let current = [];
  let i = 0;
  let x = 0;
  let y = 0;

  const w2 = width / 2;
  const h2 = height / 2;

  ctx.beginPath();

  const scale = width / baseWidth;

  for (i = 0; i < path.length; i++) {
    current = path[i];
    x = current[1] * scale;
    y = current[2] * scale;

    switch (current[0]) {
      case 'M':
        ctx.moveTo(x - w2, y - h2);
        break;
      case 'L':
        ctx.lineTo(x - w2, y - h2);
        break;

      case 'Z':
        ctx.closePath();
        break;
      default:
        break;
    }
  }

  ctx.lineWidth = 2;
  ctx.strokeStyle = 'black';
  ctx.stroke();
  ctx.fillStyle = 'transparent';
  ctx.fill();
};
