import { useEffect, useState } from 'react';

import { Box, Center, HStack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { findIndex, sumBy } from 'lodash';

import { getDesigns } from '@/api/designs';

import { Template } from '@/components/types';
import { Design } from '@/lib';

import DesignsList from './DesignsList';
import AppContainer from '@/layouts/AppContainer';
import { useMe } from '../../../api/auth';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { H2 } from '@/components/typography/Headings';
import StatBox from './StatBox';
import { useHistory, useLocation } from 'react-router-dom';
import IPsTable from './IPsTable';

const getStats = (designs: Design[]) => {
  const totalSales = sumBy(designs, 'numPurchases');

  const totalEarnings = sumBy(designs, 'totalEarnings');

  return [
    {
      name: 'Total sales',
      value: totalSales,
    },
    {
      name: 'Total earnings',
      value: totalEarnings,
    },
  ];
};

const getDesignsForCategory = (designs: Design[], category) =>
  designs.filter(({ isPublished }) => (category.isPublished ? isPublished : !isPublished));

const CATEGORIES = [
  {
    name: 'IPs',
    isPublished: true,
  },
  {
    name: 'Drafts',
    isPublished: false,
  },
];

export default function DesignCenter() {
  const { data: me } = useMe();

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(search);

  const selectedTab = searchParams.get('selectedTab');

  const defaultIndex = findIndex(CATEGORIES, (category) => category.name === selectedTab) || 0;

  const defaultTabIndex = defaultIndex === -1 ? 0 : defaultIndex;

  const [isLoading, setLoading] = useState(true);
  const [designs, setDesigns] = useState<Design[]>([]);

  useEffect(() => {}, []);

  useEffect(() => {
    getDesigns({ clientId: me.client.id, userId: me.id }).then(({ designs }) => {
      setDesigns(designs);

      setLoading(false);
    });
  }, []);

  const handleDesignUpdated = ({ id, ...rest }) =>
    setDesigns(
      designs.map((design) => {
        if (design.id === id) {
          return {
            ...design,
            ...rest,
          };
        }

        return design;
      })
    );

  const handleTabChange = (index) => {
    const selectedTab = CATEGORIES[index].name;

    history.push(`/designs?selectedTab=${selectedTab}`);
  };

  const handleDesignDeleted = (designId) => {
    setDesigns(designs.filter(({ id }) => id !== designId));
  };

  const handleUnpublishedDesign = (designId) =>
    setDesigns(
      designs.map((design) => (design.id === designId ? { ...design, isPublished: false } : design))
    );

  const templates = designs && designs.map(({ template }) => template as Template);

  return (
    <AppContainer
      contentContainerProps={{ bg: '#FFFFFF', padding: { base: '16px 12px', md: '16px' } }}
    >
      {isLoading ? (
        <Center bg="transparent" h="calc(100vh - 100px)">
          <LoadingSpinner />
        </Center>
      ) : (
        <Box>
          <H2 mb="32px">My Designs</H2>
          <HStack gap="12px" mb="30px">
            {getStats(designs).map(({ value, name }) => (
              <StatBox value={value} name={name} key={name} />
            ))}
          </HStack>
          <Tabs defaultIndex={defaultTabIndex} onChange={handleTabChange}>
            <Box overflow="auto">
              <TabList gap="40px" mb="12px" w="max-content">
                {CATEGORIES.map((category, index) => (
                  <Tab key={index}>
                    {category.name}
                    {` (${getDesignsForCategory(designs, category).length.toLocaleString(
                      'en-US'
                    )})`}
                  </Tab>
                ))}
              </TabList>
            </Box>
            <TabPanels>
              {CATEGORIES.map((category, index) => {
                const designsForCategory = designs.filter(({ isPublished }) =>
                  category.isPublished ? isPublished : !isPublished
                );

                return (
                  <TabPanel padding="20px 0 0 0" key={index}>
                    {category.isPublished ? (
                      <IPsTable
                        designs={designsForCategory}
                        onUnpublishedDesign={handleUnpublishedDesign}
                      />
                    ) : (
                      <DesignsList
                        onDesignDeleted={handleDesignDeleted}
                        onDesignUpdated={handleDesignUpdated}
                        designs={designsForCategory}
                        templates={templates}
                      />
                    )}
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </AppContainer>
  );
}
