import IconBack from './IconBack';
import IconFront from './IconFront';
import IconLeft from './IconLeft';
import IconRight from './IconRight';

export const ICONS_FOR_SIDES = {
  front: <IconFront />,
  back: <IconBack />,
  left: <IconLeft />,
  right: <IconRight />,
};
