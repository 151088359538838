import { Center, Image } from '@chakra-ui/react';

export interface ImageThumbnailProps {
  url: string;
  height?: number | string;
  width: number | string;
  imageWidth: number;
  isClickable?: boolean;
  onZoom?: () => void;
}

const ImageThumbnail = ({
  url,
  isClickable,
  height,
  imageWidth,
  width,
  onZoom,
}: ImageThumbnailProps) => (
  <Center
    bg="#EAEAEA"
    borderRadius={isClickable ? 4 : 8}
    cursor={onZoom ? 'zoom-in' : isClickable ? 'pointer' : 'auto'}
    position="relative"
    overflow="hidden"
    height={height}
    width={width}
    onClick={onZoom}
  >
    <Image
      src={url}
      width={imageWidth || '100%'}
      height={imageWidth ? 'auto' : '100%'}
      maxHeight="100%"
      objectFit="contain"
    />
  </Center>
);

export default ImageThumbnail;
