import { Icon } from '@chakra-ui/react';

const IconAiTraining = ({ color }) => (
  <Icon
    width="18px"
    height="22px"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.14205 17H0.892045L4.98864 5.36364H7.59091L11.6932 17H9.44318L6.33523 7.75H6.24432L3.14205 17ZM3.21591 12.4375H9.35227V14.1307H3.21591V12.4375ZM15.3338 5.36364V17H13.2259V5.36364H15.3338Z"
      fill={color || '#A0AEC0'}
    />
  </Icon>
);

export default IconAiTraining;
