import { Icon } from '@chakra-ui/react';

const IconArrow = (props) => (
  <Icon
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.35244 8.95269L7.00044 4.30469L11.6484 8.95269L10.9064 9.69469L7.00044 5.78869L3.09444 9.69469L2.35244 8.95269Z"
      fill="#626F82"
    />
  </Icon>
);

export default IconArrow;
