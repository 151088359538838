import { HStack, Text } from '@chakra-ui/react';
import IconCredits from '../../icons/IconCredits';

const CreditCostIcon = ({
  cost,
  isDisabled,
  outlined,
}: {
  cost: number;
  isDisabled?: boolean;
  outlined?: boolean;
}) =>
  cost ? (
    <HStack ml="4px" spacing="1px">
      <IconCredits isDisabled={isDisabled} outlined={outlined} />
      <Text>{cost}</Text>
    </HStack>
  ) : null;

export default CreditCostIcon;
