import { trackEvent } from '@/analytics';
import { getCategories, getDefaultTemplates, getTemplateBasic } from '@/api/templates';
import { Category, Size } from '@/components/types';
import { H1, H2, H3 } from '@/components/typography/Headings';

import { MockupImage, Template } from '@/lib';

import {
  Box,
  Center,
  Flex,
  Hide,
  HStack,
  Show,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { capitalize, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TemplateThumbnail from './components/TemplateThumbnail';
import TemplatePreviewGallery from './TemplatePreviewGallery';

import { DESIGN_STUDIO } from '@/constants';
import { getFormattedAmount } from '../admin/utils/currency-formatter';

import { useSizes } from '@/api/sizes';
import { SizeChart } from '../product/components/SizeChart';
import Button from '@/components/button';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

import ShareButton from '@/components/share/ShareButton';
import Panel from '@/components/panel';
import ProductDetailsZoomPage from '../product/components/product-details-zoom-page';
import { getLicenseTerm, getLicenseTermsForIp } from '@/api/story-protocol';
import IpAssetLicenseModal from './components/license-modal/IpAssetLicenseModal';

import { LicenseTerm } from '@/components/types/story-protocol';

const OVERFLOW_STYLE = {
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
};

interface SelectTemplateProps {
  onSignInToRemix?: (template: Template, ipId: string) => void;
}

const SelectTemplate = ({ onSignInToRemix }: SelectTemplateProps) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);

  const [selectedTemplate, setSelectedTemplate] = useState<Template>(null);

  const [mockupImages, setMockupImages] = useState<MockupImage[]>([]);

  const [isLoading, setLoading] = useState(true);

  const [isZoomPageVisible, setZoomPageVisible] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [licenseTerm, setLicenseTerm] = useState<LicenseTerm>(null);

  const boxRef = useRef(null);

  const [buttonWidth, setButtonWidth] = useState('');

  const {
    colors = [],
    currency,
    description,
    fit,
    gender,
    material,
    name,
    price,
  } = selectedTemplate || {};

  const variant = colors[0];

  const { data: allSizes = [] } = useSizes();

  const toast = useToast();

  const isMobile = useBreakpointValue({ base: true, md: false });

  let availableSizes: Size[] = [];

  if (variant?.sizes?.length && allSizes?.length) {
    availableSizes = variant.sizes
      .map((tcs) => allSizes.find((s) => s.id === tcs.sizeId))
      .sort((s1, s2) => s1.id - s2.id);
  }

  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const ipId = searchParams.get('sp_ipid');

  const getShareLink = () => window.location.href;
  const shareLink = getShareLink();

  const getShareContent = () => ({
    title: 'Check out this awesome merch I found on Ablo.ai',
    text: 'You can buy this merch on Ablo.ai #AIDesign #Ablo #AI #FashionAI',
    url: getShareLink(),
  });
  const canShare = navigator.share && navigator.canShare(getShareContent());

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);

      toast({
        title: 'Link copied to clipboard',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error copying link to clipboard',
        description: error.message,
        status: 'success',
      });
    }
  };

  const handleShare = async () => {
    const blob = await (await fetch(colors[0].images[0].url)).blob();
    const file = new File([blob], `${name}.png`, { type: blob.type });

    try {
      await navigator.share({ ...getShareContent(), files: [file] });
    } catch (error) {
      toast({
        title: 'Error sharing content',
        description: error?.message,
        status: 'error',
      });
    }
  };

  useEffect(() => {
    console.log('Effect');
    Promise.all([getCategories(), getDefaultTemplates()])
      .then(([categories, templates]) => {
        setCategories(categories);
        setTemplates(templates);

        setSelectedTemplate(
          templates.filter((template) => template.categoryId === categories[0].id)[0]
        );
      })
      .finally(() => {
        setLoading(false);
      });

    const loadIpAsset = async () => {
      const ipAssetLicenseTerms = await getLicenseTermsForIp(ipId);

      const { data: licenseTerms } = ipAssetLicenseTerms;

      if (!isEmpty(licenseTerms)) {
        const licenseTerm = await getLicenseTerm(licenseTerms[0].licenseTermsId);

        setLicenseTerm(licenseTerm);
      }
    };

    if (ipId) {
      loadIpAsset();
    }
  }, []);

  useEffect(() => {
    const loadTemplateDetails = async () => {
      const templateDetails = await getTemplateBasic(selectedTemplate.id);

      const { mockupImages, sides } = templateDetails;

      setSelectedTemplate({ ...selectedTemplate, sides });
      setMockupImages(mockupImages);
    };

    if (!selectedTemplate?.id) {
      return;
    }

    loadTemplateDetails();
  }, [selectedTemplate?.id]);

  useEffect(() => {
    if (isEmpty(categories)) {
      return;
    }

    trackEvent('template_page_opened', {
      popup_type: 'Template Selection',
      template_category: categories[0].name,
    });
  }, [categories]);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    const updateButtonWidth = () => {
      if (boxRef.current) {
        setButtonWidth(`${boxRef.current.offsetWidth}px`);
      }
    };

    // Create a ResizeObserver to observe changes in the container's size
    const resizeObserver = new ResizeObserver(updateButtonWidth);
    if (boxRef.current) {
      resizeObserver.observe(boxRef.current); // Start observing the container
    }

    // Initial width update
    updateButtonWidth();

    return () => {
      if (boxRef.current) {
        resizeObserver.unobserve(boxRef.current);
      }
    };
  }, [boxRef.current, isMobile]);

  const handleTabChange = (categoryId: string) => {
    setSelectedTemplate(templates.filter((template) => template.categoryId === categoryId)[0]);
  };

  const handleCreateDesign = () => {
    if (onSignInToRemix) {
      onSignInToRemix(selectedTemplate, ipId);

      return;
    }

    history.push(
      `/designs/draft?templateId=${selectedTemplate.id}${ipId ? `&parentIpId=${ipId}` : ''}`
    );
  };

  const trackTemplateSelected = (template: Template) => {
    trackEvent('template_selected', {
      template_name: template.name,
      category: categories.find(({ id }) => id === template.categoryId)?.name,
    });
  };

  if (isZoomPageVisible) {
    return (
      <ProductDetailsZoomPage
        images={[...colors[0].images.map(({ id, url }) => ({ url, id })), ...(mockupImages || [])]}
        initialImageIndex={selectedImageIndex}
        onClose={() => {
          setZoomPageVisible(false);

          setSelectedImageIndex(0);
        }}
      />
    );
  }

  if (isLoading) {
    return (
      <Center bg="transparent" h="100%">
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Box bg="#FFFFFF" h="100%" overflow={isMobile ? 'hidden' : 'auto'} w="100%">
      <Box
        h={{ base: 'calc(100dvh - 160px)', md: '100%' }}
        overflow={{
          base: 'auto',
          md: 'initial',
        }}
        padding={{ base: '18px 16px', md: '28px 32px' }}
        w="100%"
      >
        <H1 mb={{ base: '16px', md: '32px' }}>Select Template</H1>
        <Tabs
          display="flex"
          flexDirection="column"
          flex={1}
          onChange={(index) => handleTabChange(categories[index].id)}
          variant="unstyled"
        >
          <Box overflow="auto" sx={OVERFLOW_STYLE}>
            <TabList>
              {categories.map((category) => (
                <Tab key={category.id}>{category.name}</Tab>
              ))}
            </TabList>
          </Box>
          <TabPanels flex={1}>
            {categories.map((category) => {
              const templatesForCategory = templates.filter(
                ({ categoryId }) => categoryId === category.id
              );

              return (
                <TabPanel h="100%" padding="0" key={category.id}>
                  <HStack
                    gap="12px"
                    overflow="auto"
                    padding={{ base: '20px 0', md: '24px 0' }}
                    overflowX="auto"
                  >
                    {templatesForCategory.map((template, index) => {
                      const { id } = template;

                      const isSelected = id === selectedTemplate?.id;

                      return (
                        <Box key={index}>
                          <TemplateThumbnail
                            onClick={() => {
                              setSelectedTemplate(template);
                              trackTemplateSelected(template);
                            }}
                            template={template}
                            isSelected={isSelected}
                          />
                        </Box>
                      );
                    })}
                  </HStack>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
        <Stack
          alignItems="flex-start"
          direction={{ base: 'column', md: 'row' }}
          h={{ base: 'auto', md: 'calc(100% - 44px)' }}
          spacing={{ base: '12px', md: '50px' }}
        >
          <TemplatePreviewGallery
            template={{ ...selectedTemplate, mockupImages }}
            onZoom={() => setZoomPageVisible(true)}
            onSelectedIndex={setSelectedImageIndex}
          />
          <Box
            h={{ base: '100%', md: '536px' }}
            position="relative"
            w="100%"
            ref={boxRef}
            pb={{ base: 0, md: '90px' }}
          >
            <Box w={{ base: '100%', md: 'inherit' }} h="100%" overflow={{ md: 'auto' }}>
              <Flex align="center" justify="space-between" mb={{ base: '8px', md: '17px' }}>
                <Show above="md">
                  <H3>{name}</H3>
                </Show>
                <Hide above="md">
                  <H2>{name}</H2>
                </Hide>
                <ShareButton
                  canShare={canShare}
                  onCopyLink={handleCopyLink}
                  onShare={handleShare}
                />
              </Flex>
              <Text mb={{ base: '12px', md: '17px' }} textStyle="button">
                {getFormattedAmount(currency?.name, price)}
              </Text>
              <Text textStyle="bodySmall" mb={{ base: '22px', md: '30px' }}>
                {capitalize(gender)} / {fit}
              </Text>
              <Panel title="More Info" noBorderBottom w="100%">
                <Text color="secondaryDarkGray.600" textStyle="bodySmall">
                  {description || 'No description'}
                </Text>
              </Panel>
              <Panel title="Material" noBorderBottom w="100%">
                <Text color="secondaryDarkGray.600" textStyle="bodySmall">
                  {material}
                </Text>
              </Panel>
              {availableSizes.length > 1 && (
                <SizeChart templateId={selectedTemplate.id} width="100%" />
              )}
              <HStack
                position="fixed"
                bottom={{ base: '16px', md: '16px' }}
                left={{ base: '16px', md: 'auto' }}
                right={{ base: '16px', md: '32px' }}
                spacing="12px"
                zIndex={2}
                width={{ base: 'auto', md: buttonWidth }}
              >
                <Button onClick={handleCreateDesign} target={DESIGN_STUDIO} w="100%">
                  Design
                </Button>
              </HStack>
            </Box>
          </Box>
        </Stack>
        {licenseTerm ? (
          <IpAssetLicenseModal
            licenseTerm={licenseTerm}
            onClose={() => setLicenseTerm(null)}
            onStart={() => {
              setLicenseTerm(null);
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default SelectTemplate;
