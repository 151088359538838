import { HStack, IconButton } from '@chakra-ui/react';
import SHAPES, { CropShape } from './shapes';

const BUTTON_SIZE = 28;

interface CropShapesProps {
  selectedShape: string;
  onSelectedShape: (shape: CropShape) => void;
}

const CropShapes = ({ selectedShape, onSelectedShape }: CropShapesProps) => (
  <HStack>
    {SHAPES.map(({ value, Component }) => {
      const isSelected = value === selectedShape;

      return (
        <IconButton
          aria-label={value}
          key={value}
          bg={isSelected ? 'gray.200' : '#FFFFFF'}
          borderRadius="8px"
          onClick={() => onSelectedShape(value)}
          px="4px"
          h={`${BUTTON_SIZE}px`}
          minW="none"
          w={`${BUTTON_SIZE}px`}
        >
          <Component />
        </IconButton>
      );
    })}
  </HStack>
);

export default CropShapes;
