import { fabric } from 'fabric-spacerunners';

import { CropShape } from './shapes';
import { CanvasObject } from '@/lib/types';

const heartSVGPath =
  'M 272.70141,238.71731 \
      C 206.46141,238.71731 152.70146,292.4773 152.70146,358.71731  \
      C 152.70146,493.47282 288.63461,528.80461 381.26391,662.02535 \
      C 468.83815,529.62199 609.82641,489.17075 609.82641,358.71731 \
      C 609.82641,292.47731 556.06651,238.7173 489.82641,238.71731  \
      C 441.77851,238.71731 400.42481,267.08774 381.26391,307.90481 \
      C 362.10311,267.08773 320.74941,238.7173 272.70141,238.71731  \
      Z ';

const CropMaskProps = {
  isCropMask: true,
  fill: 'rgba(0,0,0,0.3)',
  stroke: 'black',
  opacity: 1,
  originX: 'left',
  originY: 'top',
  hasRotatingPoint: false,
  transparentCorners: false,
  cornerColor: 'white',
  cornerStrokeColor: 'black',
  borderColor: 'black',
  cornerSize: 20 * 3,
  padding: 0,
  height: 150,
  width: 150,
  cornerStyle: 'circle',
  borderDashArray: [5, 5],
  excludeFromExport: true,
};

export const getCropMaskShape = (
  shapeName: CropShape,
  width: number,
  left: number,
  top: number
) => {
  let shape;

  if (shapeName === CropShape.RECTANGLE) {
    shape = new fabric.Rect({ ...CropMaskProps, left, centeredScaling: true, top });
  } else if (shapeName === CropShape.CIRCLE) {
    shape = new fabric.Circle({
      ...CropMaskProps,
      width: undefined,
      height: undefined,
      radius: width / 2,
    });
  } else if (shapeName === CropShape.ROUNDED_RECTANGLE) {
    shape = new fabric.Rect({
      ...CropMaskProps,
      rx: 20,
      ry: 20,
    });
  } else if (shapeName === CropShape.STAR) {
    shape = new fabric.Star({
      ...CropMaskProps,
      width: 200,
      height: 200,
    });
  } else if (shapeName === CropShape.HEART) {
    shape = new fabric.Path(heartSVGPath, {
      ...CropMaskProps,
      width: 200,
      height: 200,
    });
  }

  shape
    .scaleToWidth(width)
    .set({
      left,
      centeredScaling: true,
      top,
    })
    .setCoords();

  return shape;
};

export const getAppliedCropMask = (shapeName: CropShape, croppingMask: CanvasObject) => {
  const commonProps = {
    left: croppingMask.left,
    top: croppingMask.top,
    originX: 'left',
    originY: 'top',
    absolutePositioned: true,
  };

  let cropMask;

  if (shapeName === CropShape.RECTANGLE) {
    cropMask = new fabric.Rect({
      ...commonProps,
      width: croppingMask.getScaledWidth(),
      height: croppingMask.getScaledHeight(),
    });
  } else if (shapeName === CropShape.CIRCLE) {
    cropMask = new fabric.Circle({
      ...commonProps,
      radius: croppingMask.radius * croppingMask.scaleX,
    });
  } else if (shapeName === CropShape.ROUNDED_RECTANGLE) {
    cropMask = new fabric.Rect({
      ...commonProps,
      rx: croppingMask.rx,
      ry: croppingMask.ry,
      width: croppingMask.getScaledWidth(),
      height: croppingMask.getScaledHeight(),
    });
  } else if (shapeName === CropShape.STAR) {
    cropMask = new fabric.Star({
      ...commonProps,
      width: croppingMask.getScaledWidth(),
      height: croppingMask.getScaledHeight(),
    });
  } else if (shapeName === CropShape.HEART) {
    cropMask = new fabric.Path(heartSVGPath, {
      ...commonProps,
      originX: 'left',
      originY: 'top',
    });

    cropMask.scaleToWidth(croppingMask.getScaledWidth());
  }

  return cropMask;
};
