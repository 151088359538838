import AppContainer from '@/layouts/AppContainer';

import SelectTemplate from './SelectTemplate';

const SelectTemplatePage = () => {
  return (
    <AppContainer
      contentContainerProps={{
        padding: 0,
      }}
    >
      <SelectTemplate />
    </AppContainer>
  );
};

export default SelectTemplatePage;
