import Panel from '@/components/panel';
import { Template } from '@/lib';

import { Box, Text } from '@chakra-ui/react';

interface MoreInfoProps {
  template: Template;
  onToggleExpanded?: (isExpanded: boolean) => void;
}

const MoreInfo = ({ template, onToggleExpanded }: MoreInfoProps) => {
  const { madeIn, material } = template;

  return (
    <Panel title="More Info" noBorderBottom onToggleExpanded={onToggleExpanded} w="100%">
      <Box color="#000000" p={{ base: '0', md: '0 0 22px 0' }}>
        <Text color="secondaryDarkGray.600" textStyle="bodySmall">
          Made in {madeIn}
        </Text>
        <Text color="secondaryDarkGray.600" textStyle="bodySmall" mt="8px">
          {material}
        </Text>
      </Box>
    </Panel>
  );
};

export default MoreInfo;
