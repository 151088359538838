import { Button, Center, Flex, Hide, HStack, useBreakpointValue } from '@chakra-ui/react';

import ButtonCTA from '@/lib/components/Button';
import { Template } from '@/lib/types';

import HintPopover from '../components/hints/HintPopover';
import { getCanvasHintProps, Hint } from '../components/hints/hints';
import IconArrowRight from '@/lib/components/icons/IconArrowRight';

const BUTTON_PROPS = {
  borderRadius: '6px',
  h: '28px',
  fontSize: '11px',
  padding: '7px 12px',
  w: '83px',
};

type Props = {
  activeHint: Hint;
  isQuickSaving?: boolean;
  onNextHint: (hint: Hint) => void;
  onBack?: () => void;
  onNext?: () => void;
  onAddToCart?: () => void;
  template: Template;
};

export default function Navbar(props: Props) {
  const { onBack, onNext, onAddToCart, isQuickSaving, activeHint, onNextHint } = props;

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Flex
      align="center"
      bg="#FFFFFF"
      borderBottom="1px solid #E7E7E7"
      height={!isMobile ? 0 : '63px'}
      justify="space-between"
      padding={0}
      pl={onBack ? 0 : '20px'}
      pr="16px"
      overflow="auto"
    >
      <Hide above="lg">
        {onBack ? (
          <Button variant="ghost" bg="transparent" onClick={onBack}>
            <IconArrowRight transform="rotate(180deg)" />
          </Button>
        ) : null}
      </Hide>
      <Hide above="lg">
        <HStack>
          <ButtonCTA
            {...BUTTON_PROPS}
            color="#000000"
            isDisabled={isQuickSaving}
            variant="secondary"
            onClick={onAddToCart}
            title=" Add to Cart"
          />
          {onNext ? (
            <HintPopover
              isOpen={activeHint === Hint.FINISH_AND_SHARE}
              {...getCanvasHintProps(Hint.FINISH_AND_SHARE)}
              offset={[0, 20]}
              onNext={() => onNextHint(null)}
              onClose={() => onNextHint(null)}
            >
              <Center
                {...(activeHint === Hint.FINISH_AND_SHARE
                  ? {
                      bg: '#FFFFFF',
                      borderRadius: '12px',
                      h: '42px',
                      pl: '12px',
                      zIndex: 4,
                    }
                  : {})}
              >
                <ButtonCTA
                  {...BUTTON_PROPS}
                  isDisabled={isQuickSaving}
                  onClick={onNext}
                  title="Publish"
                />
              </Center>
            </HintPopover>
          ) : null}
        </HStack>
      </Hide>
    </Flex>
  );
}
