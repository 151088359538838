import { ReactNode, useEffect, useState } from 'react';

import { Box, Flex, HStack, Image } from '@chakra-ui/react';

import Button from '@/components/button';

import SignInModal from '@/views/auth/signin/SignInModal';
import SignUpModal from '@/views/auth/signup/SignUpModal';
import { StorageKeys } from '@/constants';
import Sidebar, { SidebarResponsive } from '@/components/sidebar';
import { Route } from '@/components/types';

const { IS_SIDEBAR_COLLAPSED } = StorageKeys;

interface GuestPageContainerProps {
  children: ReactNode;
  showSignInModal?: boolean;
  onCancelSignIn?: () => void;
  onSignedIn: () => void;
  pageName: string;
  rightSideNavbarContent?: ReactNode;
}

export default function GuestPageContainer({
  children,
  showSignInModal,
  onCancelSignIn,
  onSignedIn,
  pageName,
  rightSideNavbarContent,
}: GuestPageContainerProps) {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const [pendingRoute, setPendingRoute] = useState<Route>(null);

  useEffect(() => {
    setSignInModalVisible(showSignInModal);
  }, [showSignInModal]);

  const [isSignInModalVisible, setSignInModalVisible] = useState(false);
  const [isSignUpModalVisible, setSignUpModalVisible] = useState(false);

  const handleToggleSidebarCollapsed = (isCollapsed) => {
    localStorage.setItem(IS_SIDEBAR_COLLAPSED, isCollapsed);

    setSidebarCollapsed(isCollapsed);
  };

  const handleSignedIn = () => {
    if (pendingRoute) {
      window.location.href = pendingRoute.defaultPath;

      return;
    }

    onSignedIn();
  };

  const handleRouteClick = (route: Route) => {
    setPendingRoute(route);

    setSignInModalVisible(true);
  };

  const isCollapsed = JSON.parse(localStorage.getItem(IS_SIDEBAR_COLLAPSED));

  useEffect(() => {
    setSidebarCollapsed(isCollapsed);
  }, [isCollapsed]);

  return (
    <Box h="100vh">
      <Box bg="#FFFFFF" borderBottom="1px solid #E2E8F0" p="30px 20px 0px 20px">
        <Flex justify={{ base: 'space-between', md: 'center' }}>
          <SidebarResponsive onRouteClick={handleRouteClick} />
          <Box display={{ base: 'none', lg: 'block' }} />
          <Image src="/AbloLogoBlack.png" alt="Ablo" width={{ base: '41px', md: '49px' }}></Image>
          <HStack position={{ base: 'static', md: 'absolute' }} top="30px" right="20px">
            {rightSideNavbarContent}
            <Button
              onClick={() => setSignInModalVisible(true)}
              outlined
              label="Sign In"
              h="30px"
              borderColor="#CBD5E0"
              color="#000000"
              fontWeight={600}
              _hover={{
                bg: 'transparent',
              }}
              target="Sign In Modal"
            />
          </HStack>
        </Flex>
        <Box mt="20px" w="100%" />
      </Box>
      <Flex h="calc(100% - 80px)" w="100%" position="relative">
        <Sidebar
          isCollapsed={isSidebarCollapsed}
          onChangeCollapsed={handleToggleSidebarCollapsed}
          onRouteClick={handleRouteClick}
        />
        <Box flex={1} h="100%" overflow="auto">
          {children}
        </Box>
      </Flex>
      {isSignInModalVisible ? (
        <SignInModal
          onClose={() => {
            setSignInModalVisible(false);

            setPendingRoute(null);

            onCancelSignIn();
          }}
          onGoToSignUp={() => {
            setSignInModalVisible(false);
            setSignUpModalVisible(true);
          }}
          onSignedIn={() => {
            handleSignedIn();
            setSignInModalVisible(false);
          }}
          pageName={pageName}
        />
      ) : null}
      {isSignUpModalVisible ? (
        <SignUpModal
          onClose={() => {
            setSignUpModalVisible(false);

            setPendingRoute(null);

            onCancelSignIn();
          }}
          onGoToSignIn={() => {
            setSignUpModalVisible(false);
            setSignInModalVisible(true);
          }}
          onSignedIn={() => {
            handleSignedIn();

            setSignUpModalVisible(false);
          }}
          onSignedUp={() => setSignUpModalVisible(false)}
          pageName={pageName}
        />
      ) : null}
    </Box>
  );
}
