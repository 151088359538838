import { Design } from '@/lib';
import {
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { getFormattedAmount } from '../utils/currency-formatter';
import IconMoreInfo from './icons/IconMoreInfo';
import RoyaltyModulePopover from '@/components/royalties/RoyaltyModulePopover';
import { useCurrencies } from '@/api/currencies';
import { keyBy } from 'lodash';
import { Currency } from '@/components/types';
import DesignTableThumbnail from './DesignTableThumbnail';
import { Link } from 'react-router-dom';
import { isCrocsTemplate } from '@/lib/utils/special-brands';
import Button from '@/components/button';
import { updateBaseDesign } from '@/api/designs';
import { useState } from 'react';

const COLUMNS = [
  {
    name: 'IP',
    getValue: (design: Design) => <DesignTableThumbnail design={design} key={design.id} />,
    width: 54,
  },
  {
    name: 'Name',
    getContent: (design: Design) => (
      <Link to={`/products/${design.id}`}>
        <Text
          maxW="calc(100% - 20px)"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          textStyle="bodySmall"
        >
          {design.name}
        </Text>
      </Link>
    ),
  },
  {
    name: 'Price',
    getValue: (design: Design, currency: Currency) => {
      const { template } = design;

      const { price } = template;

      return getFormattedAmount(currency?.name, price);
    },
  },
  {
    name: 'Earnings per sale',
    getValue: (design: Design, currency: Currency) => {
      const { template } = design;

      const { price, royaltyPercentage } = template;

      return (
        <HStack gap="5px">
          <Text textStyle="bodySmall">
            {getFormattedAmount(currency?.name, price * (royaltyPercentage / 100))}
          </Text>
          {!isCrocsTemplate(template) ? (
            <RoyaltyModulePopover
              icon={<IconMoreInfo />}
              product={{
                ...design,
                template: { ...template, currency },
              }}
            />
          ) : null}
        </HStack>
      );
    },
  },
  {
    name: 'Total sales',
    getValue: (design: Design) => design.numPurchases,
  },
  {
    name: 'Total earnings',
    getValue: (design: Design, currency: Currency) =>
      getFormattedAmount(currency?.name, design.totalEarnings),
  },
];

interface IPsTableProps {
  designs: Design[];
  onUnpublishedDesign: (designId: string) => void;
}

const IPsTable = ({ designs, onUnpublishedDesign }: IPsTableProps) => {
  const [unpublishingDesignId, setUnpublishingDesignId] = useState<string>(null);

  const { data: currencies } = useCurrencies();

  const toast = useToast();

  const currenciesById = keyBy(currencies, 'id');

  const handleUnpublish = async (designId: string) => {
    setUnpublishingDesignId(designId);

    try {
      await updateBaseDesign({
        id: designId,
        isPublished: false,
      });

      toast({
        title: 'Design unpublished',
        status: 'success',
      });

      onUnpublishedDesign(designId);
    } catch {
      toast({
        title: 'Error unpublishing design',
        status: 'error',
      });
    } finally {
      setUnpublishingDesignId(null);
    }
  };

  return (
    <TableContainer>
      <Table sx={{ tableLayout: 'fixed' }} variant="unstyled">
        <Thead>
          <Tr borderBottom="1px solid" borderBottomColor="borderLight">
            {COLUMNS.map(({ name, width }) => {
              return (
                <Th
                  cursor="pointer"
                  fontWeight={400}
                  key={name}
                  p="6px 0"
                  textTransform="none"
                  color="secondaryDarkGray.600"
                  fontFamily="inherit"
                  width={width}
                >
                  <Text color="secondaryDarkGray.600" fontSize="xs">
                    {name}
                  </Text>
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {designs.map((design, index) => {
            const {
              template: { currencyId },
            } = design;

            const currency = currenciesById[currencyId];

            return (
              <Tr key={index} borderBottom="1px solid" borderBottomColor="borderLight">
                {COLUMNS.map(({ name, getValue, getContent, width }) => (
                  <Td fontSize="sm" h="52px" key={name} p="6px 0" width={width}>
                    {getValue ? (
                      <Text textStyle="bodySmall">{getValue(design, currency)}</Text>
                    ) : null}
                    {getContent ? getContent(design) : null}
                  </Td>
                ))}
                <Td>
                  <Button
                    borderRadius="8px"
                    h="34px"
                    p="10px"
                    fontSize="sm"
                    label="Stop Sale"
                    onClick={() => handleUnpublish(design.id)}
                    secondary
                    isLoading={unpublishingDesignId === design.id}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default IPsTable;
