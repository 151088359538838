const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-8px)',
};

export const formStyles = {
  variants: {
    floating: {
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        paddingLeft: '1px',
        paddingRight: '1px',
        input: {
          border: '1px solid',
          borderColor: 'borderSecondary',
          borderRadius: '5px',
          fontSize: 'sm',
          h: '100%',
          p: '22px 12px 6px 11px',
          _placeholder: {
            color: 'transparent',
          },
          _focus: {
            border: '2px solid',
            borderColor: 'brand.600',
          },
        },
        '.chakra-input__group + label, input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
          {
            ...activeLabelStyles,
          },
        label: {
          top: '16px',
          left: 0,
          zIndex: 2,
          position: 'absolute',
          backgroundColor: 'white',
          pointerEvents: 'none',
          mx: 3,
          transformOrigin: 'left top',
        },
      },
    },
  },
};
