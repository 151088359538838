import DesignSideImage from '@/views/demo/pages/design/components/DesignSideImage';
import {
  Box,
  Center,
  Button as ChakraButton,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { CartItem } from '@/components/types';

import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDesign } from '@/api/designs';
import { Design } from '@/lib';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

const THUMBNAIL_SIZE = 56;

const MAX_SIDES = 3;

interface OrderItemSummaryRowProps {
  item: CartItem;
  index: number;
}

const OrderItemSummaryRow = ({ item, index }: OrderItemSummaryRowProps) => {
  const [isLoadingDesign, setIsLoadingDesign] = useState(true);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [design, setDesign] = useState<Design>(null);

  const history = useHistory();

  const { designId, price, quantity, templateColorSize } = item;

  useEffect(() => {
    getDesign(designId)
      .then((design) => {
        setDesign(design);

        setIsLoadingDesign(false);
      })
      .catch((e) => console.log('E', e));
  }, []);

  if (isLoadingDesign) {
    return (
      <Center w="100%" h="80px">
        <LoadingSpinner />
      </Center>
    );
  }

  const { sides } = design;

  return (
    <Flex
      alignItems="center"
      borderTop={index === 0 ? 'none' : '1px solid'}
      borderTopColor="borderSecondary"
      key={index}
      p="6px 0"
    >
      <Box w="187px">
        <Text as="b" mb="2px" textStyle="bodySmall">
          {design?.name}
        </Text>
        <Text color="secondaryDarkGray.600" mb="7px" textStyle="bodySuperSmall">
          Size: {templateColorSize?.size?.name || '-'} | Quantity: {quantity}
        </Text>
      </Box>
      <Box ml="76px" w="237px">
        <HStack justify="flex-start" spacing="4px">
          {(sides.length < MAX_SIDES + 1
            ? sides
            : sides.slice(0, sides.length - MAX_SIDES > 1 ? MAX_SIDES : MAX_SIDES + 1)
          ).map((side) => (
            <DesignSideImage
              sideName={side.templateSide.name}
              height={`${THUMBNAIL_SIZE}px`}
              width={`${THUMBNAIL_SIZE}px`}
              imageWidth={isMobile ? 59 : 46}
              design={design}
              key={side.templateSide.name}
            />
          ))}
          {sides.length > MAX_SIDES + 1 ? (
            <Center
              borderRadius="8px"
              border="1px solid"
              borderColor="borderSecondary"
              h={`${THUMBNAIL_SIZE}px`}
              w={`${THUMBNAIL_SIZE}px`}
            >
              <Text color="secondaryDarkGray.600" textStyle="bodySuperSmall">
                +{sides.length - MAX_SIDES}
              </Text>
            </Center>
          ) : null}
        </HStack>
      </Box>
      <Box ml="77px" w="70px">
        <Text color="secondaryDarkGray.600" mb="4px" textStyle="bodySuperSmall">
          Price:
        </Text>
        <Text as="b" textStyle="body">
          ${price}
        </Text>
      </Box>
      <HStack ml="95px" w="123px">
        <ChakraButton
          color="secondaryDarkGray.600"
          h="18px"
          fontSize="sm"
          fontWeight={600}
          mb="4px"
          onClick={() => history.push(`/products/${design.id}`)}
          variant="link"
          _hover={{
            opacity: 0.7,
          }}
        >
          Product details
        </ChakraButton>
      </HStack>
    </Flex>
  );
};

export default OrderItemSummaryRow;
