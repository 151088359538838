import { HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { ICONS_FOR_SIDES } from './icons';

interface PrintableAreaPickerProps {
  sides: string[];
  onSelectedSide: (sideName: string) => void;
}

const PrintableAreaPicker = ({ sides, onSelectedSide }: PrintableAreaPickerProps) => (
  <HStack>
    {sides.map((side) => (
      <IconButton
        aria-label={side}
        key={side}
        bg="#FFFFFF"
        borderRadius="8px"
        onClick={() => onSelectedSide(side)}
        p="4px 8px"
        h="47px"
        minW="none"
        w="31px"
      >
        <VStack spacing="4px">
          {ICONS_FOR_SIDES[side.toLowerCase()]}
          <Text color="black.600" fontSize="9px" fontWeight={600}>
            {capitalize(side)}
          </Text>
        </VStack>
      </IconButton>
    ))}
  </HStack>
);

export default PrintableAreaPicker;
