import { updateDesignSide } from '@/api/designs';
import { uploadBase64ToStorage } from '@/api/storage';
import { Design, DesignSide, getCanvas, getDesignOutputImageFromJson, TemplateSide } from '@/lib';
import { getFullPrintableAreaImage } from '@/lib/utils/canvas-manufacturing-image-export';

const getManufacturingImageUrlFromDesignImage = async (
  designImage: string,
  templateSide: TemplateSide
) => {
  const manufacturingImage = await getFullPrintableAreaImage(designImage, templateSide);

  const manufacturingImageUrl = await uploadBase64ToStorage(manufacturingImage, 'image/png');

  return manufacturingImageUrl;
};

export const getManufacturingImageUrl = async (design: Design, side: DesignSide) => {
  if (!side.canvasState || !JSON.parse(side.canvasState)?.objects?.length) {
    return null;
  }

  const { template } = design;

  const { canvasState, templateSide } = side;

  const canvas = getCanvas(templateSide, template, true);

  const designImage = await getDesignOutputImageFromJson(canvas, canvasState);

  const manufacturingImageUrl = await getManufacturingImageUrlFromDesignImage(
    designImage,
    templateSide
  );

  return manufacturingImageUrl;
};

export const createMissingManufacturingImages = async (design: Design) => {
  const { sides = [] } = design;

  const sidesWithMissingManufacturingImages = sides.filter(
    (side) => side.designImage && !side.manufacturingImageUrl
  );

  const sidesWithGeneratedManufacturingImages = await Promise.all(
    sidesWithMissingManufacturingImages.map(async (side) => {
      const { designImage } = side;

      const manufacturingImage = await getFullPrintableAreaImage(designImage, side.templateSide);

      const manufacturingImageUrl = await uploadBase64ToStorage(manufacturingImage, 'image/png');

      return {
        ...side,
        manufacturingImageUrl,
      };
    })
  );

  for (const side of sidesWithGeneratedManufacturingImages.filter((side) => !!side)) {
    const { id, manufacturingImageUrl } = side;

    await updateDesignSide(design.id, {
      id,
      manufacturingImageUrl,
    });
  }

  return {
    ...design,
    sides: sidesWithGeneratedManufacturingImages,
  };
};
