const IconRight = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.09777 17.345H14.4578V8.04503H19.6057V2.65503H9.09777V4.15503H18.1057V6.54503H12.9578V15.845H9.09777V17.345Z"
      fill="black"
    />
    <path
      d="M9.10278 7.01514C10.7628 7.01514 12.1028 5.66514 12.1028 4.01514V3.01514H6.09278V4.01514C6.09278 5.67514 7.44278 7.01514 9.09278 7.01514H9.10278ZM11.1028 4.01514C11.1028 5.11514 10.2028 6.01514 9.10278 6.01514C8.00278 6.01514 7.10278 5.11514 7.10278 4.01514H11.1028Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.21948 2.65479H9.78708V4.15479H3.71948V15.8589H9.30011V17.3589H2.21948V2.65479Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8783 16.2954V8.02808H6.8783V16.2954H5.8783Z"
      fill="black"
    />
  </svg>
);

export default IconRight;
