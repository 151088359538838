import { Icon } from '@chakra-ui/react';

const IconCircle = () => (
  <Icon
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3555 16.5275C13.7831 16.5275 16.5617 13.7489 16.5617 10.3214C16.5617 6.89381 13.7831 4.11523 10.3555 4.11523C6.92799 4.11523 4.14941 6.89381 4.14941 10.3214C4.14941 13.7489 6.92799 16.5275 10.3555 16.5275ZM10.3555 18.0275C14.6115 18.0275 18.0617 14.5773 18.0617 10.3214C18.0617 6.06538 14.6115 2.61523 10.3555 2.61523C6.09956 2.61523 2.64941 6.06538 2.64941 10.3214C2.64941 14.5773 6.09956 18.0275 10.3555 18.0275Z"
      fill="black"
    />
  </Icon>
);

export default IconCircle;
