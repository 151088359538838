import GuestPageContainer from '@/layouts/GuestPageContainer';

import SelectTemplate from './SelectTemplate';
import { DESIGN_STUDIO } from '@/constants';
import { useState } from 'react';
import { Template } from '@/lib';

export default function SelectTemplatePageGuest() {
  const [isSignInModalVisible, setSignInModalVisible] = useState(false);

  const [templateForRemixing, setTemplateForRemixing] = useState<Template>(null);
  const [ipIdForRemixing, setIpIdForRemixing] = useState<string>(null);

  const handleSignedIn = () => {
    setSignInModalVisible(false);

    let destination = `/designs/new`;

    if (templateForRemixing) {
      destination = `/designs/draft?templateId=${templateForRemixing.id}${
        ipIdForRemixing ? `&parentIpId=${ipIdForRemixing}` : ''
      }`;
    }

    window.location.href = destination;
  };

  const handleSignInToRemix = (template: Template, ipId: string) => {
    setTemplateForRemixing(template);
    setIpIdForRemixing(ipId);

    setSignInModalVisible(true);
  };

  return (
    <GuestPageContainer
      showSignInModal={isSignInModalVisible}
      onCancelSignIn={() => setSignInModalVisible(false)}
      onSignedIn={handleSignedIn}
      pageName={DESIGN_STUDIO}
    >
      <SelectTemplate onSignInToRemix={handleSignInToRemix} />
    </GuestPageContainer>
  );
}
