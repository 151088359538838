import { isNumber } from 'lodash';

export const getFormattedAmount = (currencyName: string, amount: number) => {
  if (isNumber(amount)) {
    return `${amount.toLocaleString('en-US', {
      style: 'currency',
      currency: currencyName || 'USD',
      minimumFractionDigits: Math.ceil(amount % 1) * 2,
    })}`;
  }
};
