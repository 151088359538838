import { Icon } from '@chakra-ui/react';

const IconGraphics = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2458 2.71606C6.14214 2.71606 2.81543 6.04278 2.81543 10.1465C2.81543 14.2502 6.14214 17.5769 10.2458 17.5769C14.3496 17.5769 17.6763 14.2502 17.6763 10.1465C17.6763 6.04278 14.3496 2.71606 10.2458 2.71606ZM1.31543 10.1465C1.31543 5.21435 5.31371 1.21606 10.2458 1.21606C15.178 1.21606 19.1763 5.21435 19.1763 10.1465C19.1763 15.0786 15.178 19.0769 10.2458 19.0769C5.31371 19.0769 1.31543 15.0786 1.31543 10.1465Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.23438 10.4551C6.23438 12.6706 8.03037 14.4666 10.2458 14.4666C12.4613 14.4666 14.2573 12.6706 14.2573 10.4551H15.2573C15.2573 13.2228 13.0136 15.4666 10.2458 15.4666C7.47809 15.4666 5.23438 13.2228 5.23438 10.4551H6.23438Z"
      fill="black"
    />
    <path d="M7.24609 6.27039H8.34609V9.27039H7.24609V6.27039Z" fill="black" />
    <path d="M12.1461 6.27039H13.2461V9.27039H12.1461V6.27039Z" fill="black" />
  </Icon>
);

export default IconGraphics;
