import { useState } from 'react';

import { Button, Card, CardBody, Flex, HStack, Image, VStack, Text } from '@chakra-ui/react';

import { groupBy, sortBy } from 'lodash';

import { useHistory } from 'react-router-dom';

import { useTemplates } from '@/api/templates';
import { Design, TemplateColorImage } from '@/lib/types';
import { CardHeading } from './CardHeading';
import { useMe } from '../../../../api/auth';

const DEFAULT_NUMBER_OF_TEMPLATES = 3;

type PopularTemplatesProps = {
  designs: Design[];
};

export default function PopularTemplatesProps({ designs = [] }: PopularTemplatesProps) {
  const [showAll, setShowAll] = useState(false);

  const history = useHistory();
  const { data: me } = useMe();

  const { data: templates = [] } = useTemplates({ clientId: me.client.id });

  const designsByTemplateId = groupBy(designs, ({ template }) => template.id);

  const templatesUsedAtleastOnce = templates.filter(({ id }) => designsByTemplateId[id]?.length);

  const templatesSortedByPopularity = sortBy(
    templatesUsedAtleastOnce,
    ({ id }) => designsByTemplateId[id]?.length
  ).reverse();

  const templatesToShow = showAll
    ? templatesSortedByPopularity
    : templatesSortedByPopularity.slice(0, DEFAULT_NUMBER_OF_TEMPLATES);

  return (
    <Card bg="#FFFFFF" borderRadius="20px" height="fit-content" w="384px">
      <CardBody p="18px 18px 42px 18px">
        <Flex justify="space-between" mb="20px" w="100%">
          <CardHeading text="Popular Templates" />
          <Button
            bg="secondaryGray.300"
            color="brand.500"
            fontSize="sm"
            fontWeight={500}
            h="32px"
            onClick={() => setShowAll(!showAll)}
            w="78px"
          >
            {showAll ? 'Hide' : 'Show'} All
          </Button>
        </Flex>
        <VStack spacing="20px">
          {templatesToShow.map((template) => {
            const { colors, id, material, fit, name, previewImage, sides } = template;

            const { images = [] } = colors[0] || {};
            const designs = designsByTemplateId[id] || [];

            const imageUrl =
              previewImage ||
              (images as TemplateColorImage[]).find(
                ({ templateSideId }) => templateSideId === sides[0]?.id
              )?.url;

            return (
              <Flex
                align="center"
                as="button"
                bg="#FFFFFF"
                borderRadius="20px"
                boxShadow="0px 18px 40px 0px rgba(112, 144, 176, 0.12)"
                key={id}
                justify="space-between"
                onClick={() => history.push(`/template-library/edit/${id}`)}
                p="20px"
                w="100%"
              >
                <HStack>
                  <Flex
                    bg="gray.50"
                    borderRadius="20px"
                    w="66px"
                    h="66px"
                    p="25px 18px"
                    direction="column"
                    justify="center"
                  >
                    <Image src={imageUrl} w="66px" />
                  </Flex>
                  <VStack align="flex-start" textAlign="left">
                    <Text color="secondaryDarkGray.900" fontWeight={700}>
                      {name} {fit}
                    </Text>
                    <Text
                      color="secondaryGray.600"
                      fontSize="sm"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {material}
                    </Text>
                  </VStack>
                </HStack>
                <Text color="black.700" fontWeight={700}>
                  {designs.length} time{designs.length === 1 ? '' : 's'}
                </Text>
              </Flex>
            );
          })}
        </VStack>
      </CardBody>
    </Card>
  );
}
