import { useEffect, useState } from 'react';

import { Box, Center, Heading, Hide, Show, Stack, Text, VStack, useToast } from '@chakra-ui/react';
import { getTemplates } from '@/api/templates';
import { Template } from '@/components/types';

import { Design, getTemplateWithPreviewImage } from '@/lib';

import { useHistory, useLocation } from 'react-router-dom';
import { saveUserDesign } from '@/api/designs';

import Brands from './fragments/Brands';
import CraftDesignsSection from './fragments/CraftDesignsSection';
import TemplatePreviewImage from './components/TemplatePreviewImage';

import IconMagic from '@/components/icons/IconMagic';
import RemixesSubmitted from './components/RemixesSubmitted';

import KBWVideo from './components/KBWVideo';
import TemplateSelector from '@/lib/editor/template-selector';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

import { H2, H5 } from '@/components/typography/Headings';
import AddToCartModal from '@/components/cart/AddToCartModal';
import ProductList from '../products/components/ProductList';
import { CrocsBanner } from './crocs-banner/CrocsBanner';
import Button from '@/components/button';
import { DESIGN_STUDIO } from '@/constants';

interface HomeProps {
  onSignInToRemix?: () => void;
  onSignInToStartDesigning?: (templateId?: string) => void;
  onSignInToAddToCart?: (designId: string) => void;
}

export default function Home({
  onSignInToRemix,
  onSignInToStartDesigning,
  onSignInToAddToCart,
}: HomeProps) {
  const [template, setTemplate] = useState<Template>(null);
  const [isLoading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [designToAddToCart, setDesignToAddToCart] = useState<string>(null);
  const [isArtboardSelectorVisible, setArtboardSelectorVisible] = useState(false);

  const history = useHistory();
  const toast = useToast();
  const { search } = useLocation();

  useEffect(() => {
    let intervalId;

    const queryParams = new URLSearchParams(search);

    const remix = queryParams.get('remix');
    const startDesigning = queryParams.get('startDesigning');
    const addingDesignToCart = queryParams.get('designToAddToCart');

    if (startDesigning) {
      handleStartDesigning();
    }

    getTemplates({ isFeatured: true })
      .then((templates) => {
        return getTemplateWithPreviewImage(templates[0]).then((featuredTemplate) => {
          setTemplate(featuredTemplate);

          if (remix) {
            handleRemix(featuredTemplate);
          } else if (addingDesignToCart) {
            handleAddToCart(addingDesignToCart);
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });

    return () => clearInterval(intervalId);
  }, []);

  const handleAddToCart = (designId: string) => {
    if (onSignInToAddToCart) {
      onSignInToAddToCart(designId);

      return;
    }

    setDesignToAddToCart(designId);
  };

  const handleSelectedTemplate = (template) => {
    history.push(`/designs/draft?templateId=${template.id}`);

    setArtboardSelectorVisible(false);
  };

  const handleGoToProducts = () => history.push('products');

  const handleStartDesigning = (templateId?: string) => {
    if (onSignInToStartDesigning) {
      onSignInToStartDesigning(templateId);

      return;
    }

    if (templateId) {
      handleSelectedTemplate(template);
    } else {
      setArtboardSelectorVisible(true);
    }
  };

  const handleExploreFeaturedCollection = () => {
    history.push(`/products?templateId=${template.id}&categoryId=${template.categoryId}`);
  };

  const handleGoToFeaturedBrandPage = () => {
    if (template.brands[0]?.slug) {
      history.push(`/brands/${template.brands[0].slug}`);
    } else {
      handleExploreFeaturedCollection();
    }
  };

  const handleRemix = async (featuredTemplate?) => {
    if (onSignInToRemix) {
      onSignInToRemix();

      return;
    }

    const templateToUse = featuredTemplate || template;

    const { colors, id } = templateToUse;

    const sides = templateToUse.sides.map((side) => ({
      templateSideId: side.id,
    }));

    const params = {
      sides,
      template,
      templateId: id,
      templateColorId: colors[0].id,
    };

    setWaiting(true);

    try {
      const newDesign = await saveUserDesign(params as Design);

      history.push(`/designs/${newDesign.id}`);
    } catch (e) {
      toast({
        title: 'Error creating a remix',
        status: 'error',
      });
    } finally {
      setWaiting(false);
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Center bg="transparent" h="calc(100vh - 100px)">
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Center bg="#FFFFFF" h="100%" w="100%">
      <Box
        h="100%"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none', // IE and Edge
          scrollbarWidth: 'none', // Firefox
        }}
        pb={{ base: '80px', lg: 0 }}
        pt={{ base: 0, md: '32px' }}
        px={{ base: 0, '2sm': '32px' }}
        w={{ base: '100%', xl: '1170px' }}
      >
        <CrocsBanner
          onStartDesigning={() => handleStartDesigning(template?.id)}
          onExploreCollection={handleGoToFeaturedBrandPage}
        />
        <Stack
          mt="60px"
          align={{ base: 'center', '2sm': 'flex-start', lg: 'center' }}
          justify={{ base: 'center', lg: 'space-between' }}
          direction={{ base: 'column', lg: 'row' }}
          mb={{ base: '42px', '2sm': '72px' }}
          px={{ base: '16px', '2sm': 0 }}
          spacing={{ base: '8px', xl: '24px' }}
          w="100%"
        >
          <Box flex={1}>
            <Heading
              fontSize={{ base: '30px', md: '46px' }}
              maxW={{ base: '260px', md: '304px' }}
              mb={{ base: '32px', lg: 0 }}
            >
              Create. Collaborate. Earn.
            </Heading>
            <Hide above="xl">
              <KBWVideo width="100%" />
            </Hide>
            <Text
              color="secondaryDarkGray.600"
              mt={{ base: '32px', '2sm': '18px' }}
              mb={{ base: '24px', '2sm': '32px' }}
              w={{ base: '340px', '2sm': '100%' }}
              textStyle="body"
            >
              Design with AI, partner with leading brands, and monetize your creativity on Web3.
            </Text>
            <Stack direction={{ base: 'column', '2sm': 'row' }} w={{ base: '100%', lg: '416px' }}>
              <Button
                flex={{ base: 'auto', md: 1 }}
                label="Design"
                onClick={() => handleStartDesigning()}
                target="Template Selection Page"
              />
              <Button
                flex={{ base: 'auto', md: 1 }}
                label="Shop Now"
                onClick={handleGoToProducts}
                secondary
                target="Merch Shop"
              />
            </Stack>
          </Box>
          <Show above="xl">
            <KBWVideo height="391px" />
          </Show>
          <Hide above="md">
            <Box />
          </Hide>
        </Stack>

        <Box mb={{ base: '12px', '2sm': '64px', lg: 0 }} />
        <Brands />
        <CraftDesignsSection onAction={() => handleStartDesigning()} />
        {template && (
          <Hide below="2sm">
            <Hide above="lg">
              <H2 alignSelf="center" textAlign="center" m="64px auto 32px auto" w="501px">
                {template.cta}
              </H2>
            </Hide>
          </Hide>
        )}
        {template && (
          <Stack
            direction={{ base: 'column', '2sm': 'row' }}
            justify="center"
            mt={{ base: '24px', lg: '64px' }}
            spacing={{ base: 0, '2sm': '32px', lg: '50px' }}
          >
            <Show above="2sm">
              <TemplatePreviewImage template={template} />
            </Show>
            <VStack
              align={{ base: 'center', '2sm': 'flex-start' }}
              mb={{ base: '32px', '2sm': '60px', lg: '16px' }}
              p={{ base: 0, lg: '17px 0' }}
              maxW={{ base: 'none', lg: '420px' }}
              spacing={{ base: '12px', lg: 0 }}
              w="100%"
            >
              <Show above="lg">
                <H2
                  alignSelf="flex-start"
                  textAlign="left"
                  ml={{ base: '16px', md: 0 }}
                  mb={{ base: 0, lg: '24px' }}
                  w={{ base: '341px', lg: 'auto' }}
                >
                  {template.cta}
                </H2>
              </Show>
              <Hide above="2sm">
                <TemplatePreviewImage template={template} />
              </Hide>
              <VStack align="flex-start" p={{ base: '5px 16px 0 12px', lg: 0 }} w="100%">
                <H5 fontWeight={700} mb={{ base: 0, md: '6px' }}>
                  {template.name}
                </H5>
                <Text color="secondaryDarkGray.600" textStyle="body">
                  {template.collectionDescription}
                </Text>
                <Hide above="lg">
                  <RemixesSubmitted numRemixes={template.numRemixes} />
                </Hide>
                <Stack
                  direction={{ base: 'column', lg: 'row' }}
                  mt={{ base: '5px', lg: '12px' }}
                  spacing="12px"
                  w="100%"
                >
                  <Button
                    onClick={() => handleRemix()}
                    isLoading={waiting}
                    w={{ base: '100%', lg: '204px' }}
                    label="Design"
                    target={DESIGN_STUDIO}
                  >
                    <IconMagic color="#FFFFFF" mr="6px" />
                    Design
                  </Button>
                  <Button
                    secondary
                    label="Explore Collection"
                    onClick={handleExploreFeaturedCollection}
                    target="Merch Shop"
                    w={{ base: '100%', lg: '204px' }}
                  />
                </Stack>
                <Show above="lg">
                  <RemixesSubmitted numRemixes={template.numRemixes} />
                  <Box mb="2px" />
                </Show>
              </VStack>
            </VStack>
          </Stack>
        )}

        <VStack
          m={{ base: '24px 0 40px 0', md: '60px 0' }}
          p={{ base: '0 17px', '2sm': '0 0 500px 0', lg: '0 0 100px 0' }}
        >
          <ProductList maxItems={4} />
          <Button
            mt={{ base: '20px', md: '33px' }}
            secondary
            onClick={handleGoToProducts}
            label="Explore Here"
            target="Merch Shop"
            w={{ base: '100%', md: '204px' }}
          />
        </VStack>
        {isArtboardSelectorVisible ? (
          <TemplateSelector
            onClose={() => setArtboardSelectorVisible(false)}
            onSelectedTemplate={handleSelectedTemplate}
          />
        ) : null}
        {designToAddToCart && (
          <AddToCartModal
            designId={designToAddToCart}
            goBackText="Back to list"
            onClose={() => setDesignToAddToCart(null)}
          />
        )}
      </Box>
    </Center>
  );
}
