import { Box, HStack, Image, Text, useBreakpointValue, VStack } from '@chakra-ui/react';

import { H2, H4 } from '../../../components/typography/Headings';
import { useHistory } from 'react-router-dom';

import { Brand } from '@/components/types';
import { isEmpty } from 'lodash';

interface FeaturedBrandsProps {
  brands: Brand[];
  title: string;
}

export default function FeaturedBrands({ brands, title }: FeaturedBrandsProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const history = useHistory();
  const ICON_SIZE = isMobile ? '40px' : '60px';

  if (isEmpty(brands)) {
    return null;
  }

  return (
    <Box w="100%">
      <H2 mb={{ base: '8px', md: '22px' }}>{title}</H2>
      <HStack
        overflowX="auto"
        spacing={4}
        w="100%"
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        css={{
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        {brands
          .filter((brand) => brand.isFeatured)
          .map((brand) => (
            <VStack
              key={brand.id}
              bgImage={`linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%), url(${brand.cardImageUrl})`}
              bgSize="cover"
              bgPosition="center"
              h={{ base: '140px', md: '160px' }}
              w={{ base: '236px', md: '100%' }}
              align="left"
              borderRadius={8}
              py={{ base: '11px', md: '15px' }}
              px={{ base: '11px', md: '17px' }}
              cursor="pointer"
              onClick={() => history.push(`/brands/${brand.slug || brand.id}`)}
            >
              <Image src={brand.iconUrl} alt={brand.name} w={ICON_SIZE} h={ICON_SIZE} />
              <VStack align="left" mt="auto" spacing={{ base: '0px', md: '4px' }}>
                <H4 color="white">{brand.name}</H4>
                <Text color="white" textStyle="body-sm-regular">
                  {`Products: ${brand.designsCount}`}
                </Text>
              </VStack>
            </VStack>
          ))}
      </HStack>
    </Box>
  );
}
