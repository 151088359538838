import { TemplateColorSize } from '@/components/types';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useState } from 'react';

interface SizesProps {
  sizes: TemplateColorSize[];
  onSelectedSizeId: (id: string) => void;
  selectedSizeId: string;
}

const Sizes = ({ sizes, onSelectedSizeId, selectedSizeId }: SizesProps) => {
  const [sizeHoveredId, setSizeHoveredId] = useState(null);

  return !isEmpty(sizes) ? (
    <HStack mb={{ base: '20px', md: '24px' }} mt="20px" spacing="10px">
      {sizes.map(({ id, name }) => {
        const isSelected = id === selectedSizeId;

        const isHovered = id === sizeHoveredId;

        return (
          <Flex
            align="center"
            as="button"
            bg={isSelected ? 'brand.600' : 'transparent'}
            border="1px solid"
            borderColor={
              isHovered ? 'brand.600' : isSelected ? 'transparent' : 'secondaryDarkGray.600'
            }
            borderRadius="4px"
            onClick={() => onSelectedSizeId(id)}
            h="34px"
            key={id}
            justify="center"
            w="34px"
            _hover={{
              bg: 'lightGray.400',
            }}
            onMouseEnter={() => setSizeHoveredId(id)}
            onMouseLeave={() => setSizeHoveredId(null)}
          >
            <Text
              color={isHovered ? 'brand.600' : isSelected ? '#FFFFFF' : 'secondaryDarkGray.600'}
              fontSize="sm"
              fontWeight={isSelected ? 500 : 400}
            >
              {name}
            </Text>
          </Flex>
        );
      })}
    </HStack>
  ) : null;
};

export default Sizes;
