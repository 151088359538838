import { getDesign } from '@/api/designs';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { Design } from '@/lib';
import IconLinkArrowRight from '@/lib/components/icons/IconLinkArrowRight';
import { Button, Center, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface PreviousArtworkProps {
  design: Design;
  onGoToFullHistory: () => void;
}

const PreviousArtwork = ({ design, onGoToFullHistory }: PreviousArtworkProps) => {
  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(true);
  const [designPreviewImage, setDesignPreviewImage] = useState<string>(null);

  useEffect(() => {
    getDesign(design.id)
      .then(({ sides }) => {
        setDesignPreviewImage(sides[0].previewImage);

        setIsLoadingPreviewImage(false);
      })
      .catch((e) => console.log('E', e));
  }, []);

  const { name } = design;

  return (
    <Flex
      alignItems="center"
      borderTopColor="borderLight"
      borderTopWidth="1px"
      justifyContent="space-between"
      py={{ base: '5px', md: '8px' }}
    >
      <HStack spacing={{ base: '10px', md: '13px' }}>
        {isLoadingPreviewImage || !designPreviewImage ? null : (
          <Image
            src={designPreviewImage}
            h={{ base: '43px', md: '48px' }}
            w={{ base: '46px', md: '51px' }}
          />
        )}
        {isLoadingPreviewImage ? (
          <Center
            position="absolute"
            w="51px"
            bg={isLoadingPreviewImage ? 'transparent' : 'black'}
            opacity="0.5"
            top={0}
          >
            <LoadingSpinner />
          </Center>
        ) : null}
        <VStack align="flex-start" gap="4px">
          <Text color="secondaryDarkGray.600" textStyle="bodySuperSmall">
            Previous Artwork
          </Text>
          <Text fontWeight={600} textStyle="bodySmall">
            {name.slice(0, 20)}
          </Text>
        </VStack>
      </HStack>
      <Button
        onClick={onGoToFullHistory}
        fontWeight={500}
        fontSize={{ base: '13px', md: 'sm' }}
        colorScheme="brand"
        variant="link"
      >
        Full History
        <IconLinkArrowRight color="#064AC4" />
      </Button>
    </Flex>
  );
};

export default PreviousArtwork;
