import axios from 'axios';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Design, DesignSide, IpHistoryResponse } from '@/lib/types';

import {
  getDesignSideWithPreviewImage,
  getDesignSideWithCanvasState,
} from '@/lib/utils/design-preview';

import { sortBy } from 'lodash';
import { Client, DesignLike } from '../components/types';
import { uploadBlobToStorage } from './storage';

const ENTITY = 'designs';
const URL = `/${ENTITY}`;

const orderSides = (design: Design): Design => {
  const { sides, template } = design;

  return {
    ...design,
    sides: sortBy(sides, ({ templateSide }) => templateSide.order),
    template: {
      ...template,
      sides: sortBy(template.sides, ({ order }) => order),
    },
  };
};

export const getDesignLikes = () => axios.get(`${URL}/likes`).then(({ data }) => data);

export const likeDesign = async (designId: string) => {
  try {
    const { data } = await axios.post<Promise<DesignLike>>(`${URL}/${designId}/likes`);

    return data;
  } catch (e) {
    const { data, status } = e.response || {};

    // 409 status indicates this specific design was already liked
    const hasLikedThisDesign = status === 409;

    const errorMessage = hasLikedThisDesign
      ? 'You already liked this design'
      : data?.message || 'Error liking design';

    throw new Error(errorMessage);
  }
};

export const getIpHistory = (design: Design): Promise<IpHistoryResponse> =>
  axios.get(`${URL}/${design.id}/ip-history`).then(({ data }) => data);

export const getDesigns = (params?: {
  brandId?: string;
  clientId?: string;
  userId?: string;
  isPublished?: boolean;
  templateId?: string;
  categoryId?: string;
  take?: number;
  skip?: number;
  search?: string;
}): Promise<{ designs: Design[]; total: number }> => {
  let url = URL;

  if (params) {
    const queryParams = new URLSearchParams();

    Object.keys(params).forEach((param) => {
      if (params[param] !== null && params[param] !== 'null') {
        queryParams.append(param, params[param]);
      }
    });

    url = `${URL}?${queryParams.toString()}`;
  }

  return axios.get<{ designs: Design[]; total: number }>(url).then(({ data }) => ({
    total: data.total,
    designs: data.designs.map((design) => orderSides(design)),
  }));
};

export const useDesignsBasic = (client: Client) =>
  useQuery([ENTITY, 'basic'], () => getDesigns({ clientId: client.id }));

export const getDesign = (id: string) =>
  axios
    .get<Design>(`${URL}/${id}`)
    .then(({ data }) => orderSides(data))
    .then((design) => {
      const { sides } = design;

      return Promise.all(
        sides.map((side) =>
          getDesignSideWithCanvasState(side).then((sideWithCanvasState) =>
            getDesignSideWithPreviewImage(design, sideWithCanvasState)
          )
        )
      ).then((newSides) => ({
        ...design,
        sides: newSides,
      }));
    });

export const useDesign = (id: string) => useQuery([ENTITY, id], () => getDesign(id));

const createDesignSide = (designSide: DesignSide, designId): Promise<DesignSide> => {
  const defaultParameters = {
    hasGraphics: false,
    hasText: false,
    designImage: 'default',
    previewImage: 'default',
  };

  return axios
    .post<DesignSide>(`${URL}/${designId}/sides`, { ...defaultParameters, ...designSide })
    .then(({ data }) => data);
};

export const createDesign = (design: Design | { id: string }): Promise<Design> => {
  return axios.post<Design>(URL, design).then(({ data }) => data);
};

const createUserDesign = (design: Design): Promise<Design> => {
  const { sides } = design;

  return createDesign(design).then((data) => {
    const designId = data.id;

    return Promise.all(sides.map((side) => createDesignSide(side, designId))).then((newSides) => ({
      ...data,
      sides: newSides,
    }));
  });
};

export const updateBaseDesign = (design: Partial<Design>) => {
  return axios.patch<Design>(`${URL}/${design.id}`, design).then(({ data }) => data);
};

export const updateDesignSide = async (designId, updates: Partial<DesignSide>) => {
  const { id } = updates;

  // Upload to storage if canvasState is present
  if (updates.canvasState) {
    const canvasStateUrl = await uploadBlobToStorage(updates.canvasState, 'application/json');

    updates.canvasStateUrl = canvasStateUrl;
    updates.canvasState = undefined;
  }

  return axios
    .patch<DesignSide>(`${URL}/${designId}/sides/${id}`, updates)
    .then(({ data }) => data);
};

const updateUserDesign = (design: Design) => {
  const { id, name, sides } = design;

  return updateBaseDesign({ id, name }).then((updatedDesign) =>
    Promise.all(sides.map((side) => updateDesignSide(design.id, side))).then((newSides) => ({
      ...updatedDesign,
      sides: newSides,
    }))
  );
};

export const saveDesign = (design: Design) => {
  const method = design.id ? updateBaseDesign : createDesign;

  return method(design);
};

export const saveUserDesign = (design: Design) => {
  const method = design.id ? updateUserDesign : createUserDesign;

  return method(design);
};

export const deleteDesign = (designId) => axios.delete(`${URL}/${designId}`);

export const useDeleteDesign = () => {
  const client = useQueryClient();

  const { mutate: removeDesign } = useMutation((id: string) => deleteDesign(id), {
    onMutate: async (id: string) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await client.cancelQueries({ queryKey: [ENTITY] });

      // Snapshot the previous value
      const oldItems = client.getQueryData<Design[]>([ENTITY]);

      // Optimistically update to the new value
      if (oldItems) {
        client.setQueryData<Design[]>([ENTITY], (old: Design[]) =>
          old.filter((design) => design.id !== id)
        );
      }

      // Return a context object with the snapshotted value
      return { oldItems };
    },
  });

  return {
    removeDesign,
  };
};

export const useUpdateBasicDesign = () => {
  const client = useQueryClient();

  const { mutateAsync: updateDesign, isLoading } = useMutation(updateBaseDesign, {
    onSuccess: () => {
      client.invalidateQueries(['designs']);
    },
  });

  return {
    updateDesign,
    isUpdating: isLoading,
  };
};

export const addDesignGraphic = (
  design: Design,
  graphicId: string
): Promise<{ designId: string; graphicId: string }> =>
  axios.post(`${URL}/${design.id}/graphics`, { graphicId }).then(({ data }) => data);

export const removeDesignGraphic = (
  design: Design,
  graphicId: string
): Promise<{ designId: string; graphicId: string }> =>
  axios.delete(`${URL}/${design.id}/graphics/${graphicId}`).then(({ data }) => data);
