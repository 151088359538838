import FormInput from '@/components/form/FormInput';
import { Text } from '@chakra-ui/react';

const DesignNameInput = ({ error, ...rest }) => (
  <FormInput
    name="Merch Name"
    description={
      <>
        <Text
          as="span"
          color="secondaryDarkGray.600"
          fontWeight={700}
          mr="2px"
          textStyle="bodySuperSmall"
        >
          Tip:
        </Text>
        <Text as="span" color="secondaryDarkGray.600" fontWeight={400} textStyle="bodySuperSmall">
          Keep the name short and clear. Use simple words that customers may type to search for your
          design
        </Text>
      </>
    }
    error={error}
    maxCharacters={21}
    {...rest}
  />
);

export default DesignNameInput;
