import { Input, InputGroup, InputProps, InputRightElement, Text } from '@chakra-ui/react';

interface InputWithMaxCharactersProps extends InputProps {
  maxCharacters: number;
  onChange: ({ target: { value } }) => void;
  value: string;
}

const InputWithMaxCharacters = (props: InputWithMaxCharactersProps) => {
  const { maxCharacters, onChange, value } = props;

  const handleChange = (e) => {
    const { value } = e.target;

    onChange({ target: { value: value.slice(0, maxCharacters) } });
  };

  return (
    <InputGroup>
      <Input {...props} onChange={handleChange} />
      <InputRightElement right="12px" top="5px">
        <Text color="secondaryGray.400" fontWeight={400} textStyle="bodySuperSmall">
          {value?.length || 0}/{maxCharacters}
        </Text>
      </InputRightElement>
    </InputGroup>
  );
};

export default InputWithMaxCharacters;
