import { createDesign, getDesign } from '@/api/designs';
import { useSizes } from '@/api/sizes';
import { useCategories } from '@/api/templates';
import Button from '@/components/button';
import AddToCartModal from '@/components/cart/AddToCartModal';
import IconCart from '@/components/icons/IconCart';
import IconChevronRight from '@/components/icons/IconChevronRight';
import { H3 } from '@/components/typography/Headings';

import { Design, getPreviewImage } from '@/lib';
import { getFormattedAmount } from '@/views/admin/utils/currency-formatter';

import DesignPreviewGallery from '@/views/demo/pages/design/components/DesignPreviewGallery';
import CreatorAvatar from '@/views/home/components/CreatorAvatar';
import { Box, Center, Flex, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { capitalize, sortBy } from 'lodash';

import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SizeChart } from './SizeChart';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import Sizes from './Sizes';
import MoreInfo from './MoreInfo';
import { createCartItem, updateCartItem } from '@/api/cart';
import { useQueryClient } from '@tanstack/react-query';
import { createMissingManufacturingImages } from '@/utils/design';
import { Template, User } from '@/components/types';
import RoyaltiesBanner from './RoyaltiesBanner';
import IpPublishedModal from '@/views/demo/pages/submit-remix/components/IpPublishedModal';
import { isCrocsTemplate } from '../../../lib/utils/special-brands';
import { CrocsModal } from '../../../components/modals/CrocsModal';
import IconMagic from '@/components/icons/IconMagic';
import { trackEvent } from '@/analytics';
import HeaderOptions from './HeaderOptions';
import ProductDetailsZoomPage from './product-details-zoom-page';
import { DESIGN_STUDIO } from '@/constants';
import PreviousArtwork from './ip-history/PreviousArtwork';
import IpHistoryModal from './ip-history/IpHistoryModal';
import MoreProductsFromDesigner from './more-products';
import trimImage from '@/lib/utils/trim-image';

interface ProductDetailsProps {
  onSignInToAddToCart?: (selectedSizeId?: string) => void;
  onSignInToRemix?: () => void;
  onSignInToViewIps?: () => void;
  onSignInToLike?: () => void;
  me?: User;
}

const ProductDetails = ({
  onSignInToAddToCart,
  onSignInToRemix,
  onSignInToViewIps,
  onSignInToLike,
  me,
}: ProductDetailsProps) => {
  const { id } = useParams<{ id: string }>();
  const [selectedSizeId, setSelectedSizeId] = useState(null);
  const [isCrocsModalVisible, setIsCrocsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [design, setDesign] = useState<Design>(null);
  const [isAddToCartModalVisible, setAddToCartModalVisible] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const [isIpPublishedModalVisible, setIsIpPublishedModalVisible] = useState(false);
  const [isCreatingDesign, setCreatingDesign] = useState(false);

  const [isIpHistoryModalVisible, setIpHistoryModalVisible] = useState(false);

  const [isZoomPageVisible, setZoomPageVisible] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const { data: categories = [] } = useCategories();
  const { data: allSizes = [] } = useSizes();
  const history = useHistory();

  const toast = useToast();

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const returnTo = searchParams.get('returnTo');

  const queryClient = useQueryClient();

  useEffect(() => {
    if (searchParams.get('fromAcme')) {
      setIsIpPublishedModalVisible(true);
    }
  }, []);

  useEffect(() => {
    if (!design) {
      return;
    }

    const queryParams = new URLSearchParams(search);
    const addingDesignToCart = queryParams.get('addingToCart');
    const selectedSize = queryParams.get('selectedSize');

    const remixingDesign = queryParams.get('remixingDesign');

    const selectedSizeAsNumber = parseInt(selectedSize);

    if (addingDesignToCart) {
      handleAddToCart(selectedSizeAsNumber);
    }

    if (remixingDesign) {
      handleRemix();
    }

    if (selectedSize) {
      setSelectedSizeId(selectedSizeAsNumber);
    }
  }, [design]);

  useEffect(() => {
    const loadDesign = async () => {
      const design = await getDesign(id);

      setSelectedSizeId(null);

      // The PDP is the only place where we want to display high quality preview images and not regular ones
      const designWithHighResImages = await Promise.all(
        design.sides.map((side) => {
          const { template, templateColorId } = design;

          const { designImage, templateSide } = side;

          return getPreviewImage(designImage, template, templateSide, templateColorId, true).then(
            (previewImage) => {
              if (!designImage) {
                return {
                  ...side,
                  previewImage,
                };
              }

              return trimImage(designImage).then((trimmedImage) => ({
                ...side,
                designImage: trimmedImage,
                previewImage,
              }));
            }
          );
        })
      ).then((newSides) => ({
        ...design,
        sides: newSides,
      }));

      setDesign(designWithHighResImages);

      setLoading(false);
    };

    loadDesign();
  }, [id]);

  const handleRemix = async () => {
    if (onSignInToRemix) {
      onSignInToRemix();

      return;
    }

    setCreatingDesign(true);

    try {
      const copy = await createDesign({ id: design.id });

      history.push(`/designs/${copy.id}`);
    } catch (e) {
      toast({
        title: 'Error creating a remix',
        status: 'error',
      });
    } finally {
      setCreatingDesign(false);
    }
  };

  const trackProductDetailsPageEvent = (eventName: string, props = {}) =>
    trackEvent(eventName, {
      item_name: design.name,
      item_category: categories.find(({ id }) => id === design.template.categoryId)?.name,
      ...props,
    });

  const trackViewDetailsEvent = () => trackProductDetailsPageEvent('view_item');

  const trackAddToCartQuantityPopupEvent = () =>
    trackProductDetailsPageEvent('add_to_cart_quantity_popup');

  if (loading) {
    return (
      <Center h="100vh" w="100%">
        <LoadingSpinner />
      </Center>
    );
  }

  const { original, template, templateColorId, user } = design;
  const { categoryId, colors, currency, gender, fit, price } = template as Template;

  const trackAddToCartEvent = () =>
    trackProductDetailsPageEvent('add_to_cart', {
      price,
      currency: currency?.name || 'USD',
    });

  const category = categories.find(({ id }) => id === categoryId);

  const variant = colors.find(({ id }) => id === templateColorId);

  const availableSizes = (variant?.sizes || []).map((item) => {
    const { sizeId } = item;

    const { name } = allSizes.find((size) => size.id === sizeId) || {};

    return {
      ...item,
      name,
    };
  });

  const handleUpdateCart = (cart) => {
    queryClient.setQueryData(['me'], { ...me, cart });
  };

  const handleAddToCart = async (sizeId?: number) => {
    trackAddToCartEvent();

    if (isCrocsTemplate(template)) {
      setIsCrocsModalVisible(true);

      trackEvent('shop_on_crocs_click', {
        product_id: design.id,
        product_name: design.name,
        price: design.template.price,
        category: categories.find(({ id }) => id === design.template.categoryId)?.name,
        designer_name: `${design.user.firstName} ${design.user.lastName}`,
      });

      return;
    }

    if (onSignInToAddToCart) {
      onSignInToAddToCart(selectedSizeId);
      return;
    }

    const sizeToUse = sizeId || selectedSizeId;

    if (!sizeToUse) {
      trackAddToCartQuantityPopupEvent();

      setAddToCartModalVisible(true);
      return;
    }

    const { cart } = me;

    setAddingToCart(true);

    const cartItem = cart.items.find(
      ({ templateColorSizeId }) => templateColorSizeId === sizeToUse
    );

    const size = variant.sizes.find(({ id }) => id === sizeToUse);

    try {
      if (!cartItem) {
        const params = {
          designId: design.id,
          templateColorSizeId: size.id,
          quantity: 1,
          storefrontId: size.storefrontVariantId,
        };

        await createMissingManufacturingImages(design);

        const newCartItem = await createCartItem(params);

        handleUpdateCart({
          ...cart,
          items: [...cart.items, newCartItem],
        });
      } else {
        const updatedCartItem = await updateCartItem(cartItem.id, cartItem.quantity + 1);

        handleUpdateCart({
          ...cart,
          items: cart.items.map((item) => {
            if (item.templateColorSizeId !== cartItem.templateColorSizeId) {
              return item;
            }

            return updatedCartItem;
          }),
        });
      }
    } catch (e) {
      console.error('E', e);

      toast({
        title: 'Error adding to cart',
        status: 'error',
      });
    } finally {
      setAddingToCart(false);
    }

    trackAddToCartQuantityPopupEvent();
  };

  const handleGoToMyMerch = () => history.push('/products?isShowingMyDesigns=true');

  const handleGoToIps = () => {
    if (onSignInToViewIps) {
      onSignInToViewIps();

      return;
    }

    history.push('/designs');
  };

  const handleGoBackToProducts = () =>
    history.push(returnTo || `/products?categoryId=${categoryId}`);

  if (isZoomPageVisible) {
    return (
      <ProductDetailsZoomPage
        images={[
          ...design.sides.map(({ id, previewImage }) => ({ id, url: previewImage })),
          ...design.mockupImages,
        ]}
        initialImageIndex={selectedImageIndex}
        onClose={() => {
          setZoomPageVisible(false);

          setSelectedImageIndex(0);
        }}
      />
    );
  }

  const isOwnDesign = me && design.userId === me.id;

  return (
    <Box bg="#FFFFFF" minH="100%">
      <Box
        position="relative"
        h={{ base: 'calc(100dvh - 150px)', md: '100%' }}
        padding={{ base: '12px 16px 0 12px', md: '20px 32px 0px 32px' }}
        overflow="auto"
      >
        <HStack gap="4px" mb={{ base: '12px', md: '16px' }}>
          <Text color="brand.600" onClick={handleGoBackToProducts} textStyle="breadcrumb">
            {category?.name}
          </Text>
          <IconChevronRight />
          {isOwnDesign ? (
            <>
              <Text color="brand.600" onClick={handleGoToMyMerch} textStyle="breadcrumb">
                My Merch
              </Text>

              <IconChevronRight />
            </>
          ) : null}
          <Text color="secondaryDarkGray.600" fontWeight={400} textStyle="breadcrumb">
            {design.name}
          </Text>
        </HStack>
        <Stack
          alignItems="flex-start"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '12px', md: '50px' }}
        >
          <DesignPreviewGallery
            design={design}
            onZoom={() => setZoomPageVisible(true)}
            onSelectedIndex={setSelectedImageIndex}
          />
          <Box h={{ base: '100%', md: 'auto' }} position="relative" w="100%">
            <Box w="100%" h="100%" overflow={{ md: 'auto' }}>
              {!isCrocsTemplate(design.template) && isOwnDesign && (
                <Box mb={{ base: '14px', md: '20px' }}>
                  <RoyaltiesBanner product={design} onViewIps={handleGoToIps} />
                </Box>
              )}
              <Flex align="center" justify="space-between" mb={{ base: '8px', md: '10px' }}>
                <H3>{design.name}</H3>
                <HeaderOptions design={design} onSignInToLike={onSignInToLike} />
              </Flex>
              <Text
                color="secondaryDarkGray.600"
                textStyle="bodySmall"
                mb={{ base: '12px', md: '16px' }}
              >
                {template.name}
              </Text>
              <Text mb={{ base: '12px', md: '16px' }} textStyle="button">
                {getFormattedAmount(currency?.name, price)}
              </Text>
              <Text textStyle="bodySmall" mb={{ base: '14px', md: '16px' }}>
                {capitalize(gender)} / {fit}
              </Text>
              <Sizes
                sizes={sortBy(availableSizes, 'sizeId')}
                onSelectedSizeId={setSelectedSizeId}
                selectedSizeId={selectedSizeId}
              />
              <CreatorAvatar fontSize={14} user={user} />
              <Box mb="24px" />
              {original ? (
                <PreviousArtwork
                  design={original}
                  onGoToFullHistory={() => setIpHistoryModalVisible(true)}
                />
              ) : null}
              <MoreInfo
                template={template}
                onToggleExpanded={(isExpanded) => {
                  if (isExpanded) {
                    trackViewDetailsEvent();
                  }
                }}
              />
              {availableSizes.length > 1 && <SizeChart templateId={template.id} />}
              <HStack
                bg="#FFFFFF"
                bottom={{ base: '16px', md: '16px' }}
                left={{ base: '16px', md: 0 }}
                mt={{ base: 0, md: '28px' }}
                right={{ base: '16px', md: 0 }}
                position={{ base: 'fixed', md: 'static' }}
                spacing="12px"
                zIndex={2}
              >
                <Button
                  isLoading={addingToCart}
                  onClick={() => handleAddToCart()}
                  w={{ base: '100%' }}
                >
                  <HStack>
                    <IconCart />
                    <Text>{isCrocsTemplate(template) ? 'Shop on Crocs' : 'Add to Cart'}</Text>
                  </HStack>
                </Button>
                <Button
                  isLoading={isCreatingDesign}
                  onClick={() => handleRemix()}
                  secondary
                  label="Design"
                  target={DESIGN_STUDIO}
                  w={{ base: '100%' }}
                >
                  <IconMagic color="#000000" mr="6px" />
                  Design
                </Button>
              </HStack>
            </Box>
          </Box>
        </Stack>
        <MoreProductsFromDesigner userId={design.userId} />
        {isAddToCartModalVisible && (
          <AddToCartModal
            designId={design.id}
            goBackText="Back to product"
            onClose={() => setAddToCartModalVisible(false)}
          />
        )}
        {isIpPublishedModalVisible ? (
          <IpPublishedModal design={design} onClose={() => setIsIpPublishedModalVisible(false)} />
        ) : null}
        {isCrocsModalVisible ? (
          <CrocsModal design={design} onClose={() => setIsCrocsModalVisible(false)} />
        ) : null}
        {isIpHistoryModalVisible ? (
          <IpHistoryModal design={design} onClose={() => setIpHistoryModalVisible(false)} />
        ) : null}
      </Box>
    </Box>
  );
};

export default ProductDetails;
