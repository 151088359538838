import { Brand, Template } from '@/components/types';
import { sumBy } from 'lodash';

const ALL_OPTION = { id: null, name: 'All' };

export const getTemplateOptions = (templates: Template[]) => [
  { ...ALL_OPTION, description: `Products: ${sumBy(templates, 'numRemixes')}` },
  ...templates.map(({ id, name, iconUrl, numRemixes }) => ({
    id,
    name,
    iconUrl,
    description: `Products: ${numRemixes}`,
  })),
];

export const getBrandOptions = (brands: Brand[] = []) => [
  { ...ALL_OPTION, description: `Products: ${sumBy(brands, 'designsCount')}` },
  ...brands.map(({ id, name, iconUrl, designsCount }) => ({
    id,
    name,
    iconUrl,
    description: `Products: ${designsCount}`,
  })),
];
