import { getDesigns } from '@/api/designs';
import { H3 } from '@/components/typography/Headings';
import { Design } from '@/lib';
import ProductCard from '@/views/products/components/ProductCard';
import { SimpleGrid } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

interface MoreProductsFromDesignerProps {
  userId: string;
}

const MoreProductsFromDesigner = ({ userId }: MoreProductsFromDesignerProps) => {
  const [designs, setDesigns] = useState<Design[]>([]);

  useEffect(() => {
    const loadDesigns = async () => {
      const designsResponse = await getDesigns({
        isPublished: true,
        userId,
        take: 4,
        skip: 0,
      });

      setDesigns(designsResponse.designs);
    };

    loadDesigns();
  }, []);

  if (isEmpty(designs)) {
    return null;
  }

  return (
    <>
      <H3 mb={{ base: '12px', md: '20px' }} mt={{ base: '20px', md: '48px' }}>
        More products from this designer
      </H3>
      <SimpleGrid columns={{ base: 1, md: 4 }} pb="40px" spacing="12px" w="100%">
        {designs.map((design) => (
          <ProductCard key={design.id} design={design} />
        ))}
      </SimpleGrid>
    </>
  );
};

export default MoreProductsFromDesigner;
