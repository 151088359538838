import { Center, Image } from '@chakra-ui/react';

const SIZE = 300;

interface IpHistoryPreviewImageHoverProps {
  imageUrl?: string;
}

const IpHistoryPreviewImageHover = ({ imageUrl }: IpHistoryPreviewImageHoverProps) => (
  <Center
    bg="#FFFFFF"
    borderRadius="24px"
    boxShadow="0px 8px 10px -4px rgba(11, 31, 67, 0.20)"
    h={`${SIZE}px`}
    p="10px"
    position="absolute"
    w={`${SIZE}px`}
    zIndex={9999}
  >
    <Center bg="secondaryLightGray" borderRadius="16px" h="100%" w="100%">
      <Image src={imageUrl} w="247px" h="232px" objectFit="cover" />
    </Center>
  </Center>
);

export default IpHistoryPreviewImageHover;
