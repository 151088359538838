import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { Design, getDesignPreviewImage } from '@/lib';
import { Center, Image } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const THUMBNAIL_SIZE = 40;

interface DesignTableThumbnailProps {
  design: Design;
}

const DesignTableThumbnail = ({ design }: DesignTableThumbnailProps) => {
  const [designPreviewImage, setDesignPreviewImage] = useState<string>(null);

  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(true);

  useEffect(() => {
    getDesignPreviewImage(design).then(({ previewImage }) => {
      setDesignPreviewImage(previewImage);

      setIsLoadingPreviewImage(false);
    });
  }, []);

  return (
    <Center
      bg="secondaryLightGray"
      borderRadius="3px"
      cursor="pointer"
      h={`${THUMBNAIL_SIZE}px`}
      w={`${THUMBNAIL_SIZE}px`}
      overflow="hidden"
      onClick={() => window.open(`/products/${design.id}`, '_blank')}
      position="relative"
    >
      {isLoadingPreviewImage || !designPreviewImage ? null : (
        <Image h="100%" w="100%" src={designPreviewImage} objectFit="contain" />
      )}
      {isLoadingPreviewImage ? (
        <Center
          position="absolute"
          w="100%"
          h="100%"
          bg={isLoadingPreviewImage ? 'transparent' : 'black'}
          opacity="0.5"
          top={0}
        >
          <LoadingSpinner />
        </Center>
      ) : null}
    </Center>
  );
};

export default DesignTableThumbnail;
