import axios from 'axios';

import { useQuery } from '@tanstack/react-query';
import { GraphicCategory, GraphicCollection } from '@/lib';
import { sortBy } from 'lodash';

const ENTITY_GRAPHIC_CATEGORY = `graphics-categories`;
const ENTITY_GRAPHIC_COLLECTION = `graphics-collections`;

const getGraphicCategories = () =>
  axios.get<GraphicCategory[]>(`/${ENTITY_GRAPHIC_CATEGORY}`).then(({ data }) => {
    const dataOrdered = sortBy(data, 'order');
    for (const category of dataOrdered) {
      category.collections = sortBy(category.collections, 'order');
      for (const collection of category.collections) {
        collection.graphics = sortBy(collection.graphics, 'order');
      }
    }
    return dataOrdered;
  });

export const useGraphicCategories = () => {
  const { data: graphicCategories, isLoading } = useQuery([ENTITY_GRAPHIC_CATEGORY], () =>
    getGraphicCategories()
  );

  return { graphicCategories, isLoading };
};

export const getGraphicGollections = (categoryId: string) =>
  axios
    .get<GraphicCollection[]>(`/${ENTITY_GRAPHIC_COLLECTION}?categoryId=${categoryId}`)
    .then(({ data }) => data);

export const useGraphicGollections = (categoryId: string) => {
  const { data: graphicCollections, isLoading } = useQuery(
    [ENTITY_GRAPHIC_COLLECTION, categoryId],
    () => getGraphicGollections(categoryId)
  );

  return { graphicCollections, isLoading };
};

export const getGraphicGollectionById = (id: string) =>
  axios.get<GraphicCollection>(`/${ENTITY_GRAPHIC_COLLECTION}/${id}`).then(({ data }) => data);

export const useGraphicGollectionById = (id: string) => {
  const { data: graphicCollection, isLoading } = useQuery([ENTITY_GRAPHIC_COLLECTION, id], () =>
    getGraphicGollectionById(id)
  );
  return { graphicCollection, isLoading };
};
