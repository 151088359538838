import { Icon } from '@chakra-ui/react';

const IconRoundedRectangle = () => (
  <Icon
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.60547"
      y="2.07129"
      width="11.5"
      height="16.5"
      rx="3.25"
      stroke="black"
      strokeWidth="1.5"
    />
  </Icon>
);

export default IconRoundedRectangle;
