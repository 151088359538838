const IconBack = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7726 17.5899H10.4126V16.0899H14.2726V6.7899H17.7126V4.3999H10.4126V2.8999H19.2126V8.2899H15.7726V17.5899Z"
      fill="black"
    />
    <path
      d="M10.4126 17.5899H5.05261V8.2899H1.61261V2.8999H10.4126V4.3999H3.11261V6.7899H6.55261V16.0899H10.4126V17.5899Z"
      fill="black"
    />
  </svg>
);

export default IconBack;
