import { Icon } from '@chakra-ui/react';

const IconToggle = ({ isRotated = false, ...rest }) => (
  <Icon
    transform={isRotated ? 'rotate(180deg)' : 'none'}
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.0296 16.6399L5.38965 9.99986L12.0296 3.35986L13.0896 4.41986L7.50965 9.99986L13.0896 15.5799L12.0296 16.6399Z"
      fill="black"
    />
  </Icon>
);

export default IconToggle;
